import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactTable, ReactTableDataGrid } from "components/Table";
import {
    getShops,
    deleteShop,
    refreshToken,
    updateMemberShop,
    cancelDeleteShop,
    syncInfomation,
    syncOrders,
} from "actions/shopActions";
import { getStaffs } from "actions/teamActions";
import AddShop from "./addShop";
import EditShop from "./editShop";
import ModalMember from "./modalMember";
import ViewSizeChart from "./viewSizeChart";
import { permissionComponent, FormatterStatus, formatterCreateAt } from "components/Function";
import Loading from "components/Loading";
import SelectBox from "components/elements/selectBox";
import CustomHeader from "components/elements/customHeaderTable";

const Shop = ({
    getShops,
    shop,
    deleteShop,
    user,
    refreshToken,
    getStaffs,
    updateMemberShop,
    FormatterStatus,
    cancelDeleteShop,
    formatterCreateAt,
    syncInfomation,
    syncOrders,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [shopSelect, setShopSelect] = useState(null);
    const [editMember, setEditMember] = useState(null);
    const [viewChart, setViewChart] = useState(null);
    const [openMultipleMember, setOpenMultipleMember] = useState(false);
    const [shopsSelect, setShopsSelect] = useState([]);
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);
    const [sort_by, setSortBy] = useState("");
    const [sort_order, setSortOrder] = useState("");

    const { t } = useTranslation();
    const { list, totalsize, isLoading } = shop;

    const columns = [
        {
            field: 'checkbox',
            cellClass: "centered-cell",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 50,
            sortable: false,
        },
        {
            field: "_id",
            width: 40,
            cellClass: "centered-cell",
            headerName: "",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
            sortable: false,
            // comparator: () => 0,
        },
        {
            field: "name",
            headerName: t("Name"),
            width: 220,
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => (
                <div className="my-2">
                    <div className="d-flex lh-lg">{cell}</div>
                    {row?.warehouses?.filter((v, i) => v?.effect_status === "ENABLED" && v?.type === "SALES_WAREHOUSE")
                        ?.length > 0 && (
                        <div className="d-flex lh-lg">
                            <i>
                                Warehouses Sale:{" "}
                                {
                                    row?.warehouses?.filter(
                                        (v, i) => v?.effect_status === "ENABLED" && v?.type === "SALES_WAREHOUSE"
                                    )?.length
                                }
                            </i>
                        </div>
                    )}
                    {row?.warehouses?.filter((v, i) => v?.effect_status === "ENABLED" && v?.type === "RETURN_WAREHOUSE")
                        ?.length > 0 && (
                        <div className="d-flex lh-lg">
                            <i>
                                Warehouses Return:{" "}
                                {
                                    row?.warehouses?.filter(
                                        (v, i) => v?.effect_status === "ENABLED" && v?.type === "RETURN_WAREHOUSE"
                                    )?.length
                                }
                            </i>
                        </div>
                    )}
                    {row?.size_charts?.length > 0 && (
                        <div className="d-flex">
                            <i>
                                Size Charts: {row?.size_charts?.length}{" "}
                                <button
                                    onClick={() => {
                                        setViewChart(row?.size_charts);
                                    }}
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={t("View")}
                                >
                                    <i className="ki-outline ki-eye fs-4"></i>
                                </button>
                            </i>
                        </div>
                    )}
                </div>
            ),
        },
        {
            field: "is_listing",
            headerName: t("Is Listing"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => <FormatterStatus status={cell || cell === undefined} />,
        },
        {
            field: "region",
            headerName: t("Region"),
            sortable: false,
        },
        {
            field: "status",
            headerName: t("Status"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => <FormatterStatus status={cell || "Connected"} />,
        },
        {
            field: "sync_product_status",
            headerName: t("Status Sync"),
            width: 200,
            sortable: false,
            cellRenderer: ({ value: cell }) => {
                let type = "success";
                if (cell?.status === "Running") type = "warning";
                if (cell?.status === "Error") type = "Error";
                return (
                    <>
                        <FormatterStatus type={type} status={cell?.status || "End"} />
                        <p className="m-0 lh-lg">
                            Last Sync:{" "}
                            {cell?.last_sync && cell?.last_sync > 0 ? formatterCreateAt(cell?.last_sync) : "Since 1905"}
                        </p>
                    </>
                );
            },
        },
        {
            field: "code",
            headerName: t("Code"),
            sortable: false,
        },
        {
            field: "orders_count",
            headerName: t("Total Order"),
            sortable: true,
            comparator: () => 0,
        },
        {
            field: "products_count",
            headerName: t("Total Product"),
            sortable: false,
        },
        {
            field: "note",
            headerName: t("Note"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => cell,
        },
        {
            field: "member_note",
            headerName: t("Member Note"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => cell,
        },
        {
            field: "last_order",
            headerName: t("Last Order"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                cell && cell > 0 ? formatterCreateAt(cell) : "Since 1905",
        },
        {
            field: "memberData",
            headerName: t("Members"),
            sortable: false,
            width: 350,
            cellRenderer: ({ value: cell }) =>
                cell?.map((v, i) => (
                    <p>
                        - {v?.name} ({v?.email})
                    </p>
                )),
        },
        {
            field: "-",
            headerName: t("Actions"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                permissionComponent({
                    user: user.user,
                    permission: "UPDATE_SHOP",
                    component:
                        row.status !== "Deleted" ? (
                            <>
                                <button
                                    onClick={() => {
                                        setShopSelect(row);
                                    }}
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={t("Edit Note")}
                                >
                                    <i className="ki-outline ki-notepad-edit fs-2"></i>
                                </button>
                                <button
                                    onClick={() => {
                                        if (row?.app?.authorization_link)
                                            window.location.href = row?.app?.authorization_link;
                                    }}
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={t("Reconnect")}
                                >
                                    <i className="ki-outline ki-disk fs-2"></i>
                                </button>
                                <button
                                    onClick={() => {
                                        setEditMember(row);
                                    }}
                                    className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={t("Member")}
                                >
                                    <i className="ki-outline ki-user fs-2"></i>
                                </button>
                                {/* <button
                                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-2"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Refresh Token"
                                    onClick={() => refreshToken(row._id)}
                                >
                                    <i className="ki-outline ki-arrows-loop fs-2"></i>
                                </button> */}
                                <button
                                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Delete"
                                    onClick={() => handleDelete(row._id)}
                                >
                                    <i className="ki-outline ki-trash fs-2"></i>
                                </button>
                            </>
                        ) : (
                            <button
                                className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Cancel Delete"
                                onClick={() => handleCancelDelete(row._id)}
                            >
                                <i className="ki-outline ki-delete-folder fs-2"></i>
                            </button>
                        ),
                }),
        },
    ];

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    const onSelectionChanged = (data) => {
        if (data.api) {
            const ids = data.api.getSelectedRows().map((d) => d._id);
            setShopsSelect(ids);
        }
    };

    // const onSelect = (row, isSelect) => {
    //     let shopsSelectTmp = shopsSelect;
    //     if (isSelect) {
    //         shopsSelectTmp.push(row._id);
    //     } else {
    //         shopsSelectTmp = shopsSelectTmp.filter((shopId) => shopId !== row._id);
    //     }
    //     const new_arr = JSON.stringify(shopsSelectTmp);
    //     setShopsSelect(JSON.parse(new_arr));
    // };

    // const onSelectAll = (isSelect, row) => {
    //     let shopsSelectTmp = shopsSelect;
    //     if (isSelect) {
    //         shopsSelectTmp = row.map((v, i) => v._id);
    //     } else {
    //         shopsSelectTmp = [];
    //     }
    //     setShopsSelect(shopsSelectTmp);
    // };

    // const selectRow = {
    //     mode: "checkbox",
    //     clickToSelect: false,
    //     selected: shopsSelect,
    //     onSelect: onSelect,
    //     onSelectAll: onSelectAll,
    //     selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
    //         <div className="d-flex">
    //             <input
    //                 type="checkbox"
    //                 ref={(input) => {
    //                     if (input) {
    //                         input.indeterminate = indeterminate;
    //                         input.checked = checked;
    //                     }
    //                 }}
    //                 onChange={onChange}
    //             />
    //             <span style={{ marginLeft: "8px" }}>({shopsSelect.length})</span>
    //         </div>
    //     ),
    // };

    useEffect(() => {
        getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, sort_by, sort_order });
    }, [getShops, page, sizePerPage, searchText, filter, sort_by, sort_order]);

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const handleUpdateMembers = (data) => {
        return updateMemberShop({
            members: data?.members || [],
            _id: shopsSelect,
            page,
            sizePerPage,
            searchText,
            filter,
            sort_by,
            sort_order,
        });
    };

    const handleSyncInfomation = async (data) => {
        return syncInfomation({ shops: shopsSelect, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const handleSyncOrders = async (data) => {
        return syncOrders({ shops: shopsSelect, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const handleRefreshToken = async (data) => {
        return refreshToken({ shops: shopsSelect, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const groupButton = () => {
        return (
            <div className="mb-5 d-flex gap-2 flex-wrap pb-4">
                <AddShop />
                <button onClick={() => setOpenMultipleMember(true)} className="btn btn-info btn-sm me-2 mb-2">
                    Update member
                </button>
                <ModalMember
                    data={{}}
                    isOpen={openMultipleMember}
                    onSubmit={handleUpdateMembers}
                    onClose={() => setOpenMultipleMember(false)}
                />
                <button onClick={handleSyncInfomation} className="btn btn-dark btn-sm me-2 mb-2">
                    Sync Infomation
                </button>
                <button onClick={handleSyncOrders} className="btn btn-warning btn-sm me-2 mb-2">
                    Sync Orders
                </button>
                <button onClick={handleRefreshToken} className="btn btn-info btn-sm me-2 mb-2">
                    Refresh Token
                </button>
                <SelectBox
                    options={staffs}
                    name="filter_staff"
                    value={filter.staff || ""}
                    onChange={(data) => {
                        setFilter((prev) => ({
                            ...prev,
                            staff: data.value || "",
                        }));
                        setPage(1);
                    }}
                    className=" me-2 mb-2"
                />
            </div>
        );
    };

    const handleDelete = (id) => {
        deleteShop({ id, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const handleCancelDelete = (id) => {
        cancelDeleteShop({ id, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const onTableChanged = ({ page, limit, text }) => {
        if (limit) setSizePerPage(limit);
        if (page) setPage(page);
        if (typeof text === "string") setSearchText(text);
    };

    const defaultColDef = useMemo(() => ({
        wrapText: true,
        autoHeight: true,
        autoHeaderHeight: true,
    }), []);

    
    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onSortChanged = (e) => {
        if (e.source === "uiColumnSorted") {
            console.log(e.api.setSuppressAnimations);
            const sortedColumns = e.api.getColumnState().filter((col) => col.sort);
            console.log(sortedColumns?.[0]?.colId, sortedColumns?.[0]?.sort);

            setSortBy(sortedColumns?.[0]?.colId);
            setSortOrder(sortedColumns?.[0]?.sort || "desc");
        }
    };

    const onFirstDataRendered = (params) => {
        params.columnApi.autoSizeColumns(["name", "is_listing", "region", "status", "sync_product_status", "code", "orders_count", "products_count", "note", "member_note", "last_order", "memberData"]);
    };

    return (
        <div className="card mb-5 mb-xl-10">
            <Loading isLoading={isLoading} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    {/* <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                        isSelectRow={true}
                        selectRow={selectRow}
                    /> */}
                    <ReactTableDataGrid
                        tableName="shopConnect"
                        idProperty="_id"
                        columns={columns}
                        rowData={list}
                        defaultColDef={defaultColDef}
                        gridOptions={{
                            reactiveCustomComponents: true,
                        }}
                        paginationPageSize={sizePerPage}
                        totalSize={totalSize}
                        onTableChanged={onTableChanged}
                        emptyText={t("No data!")}
                        rowSelection="multiple"
                        onSelectionChanged={onSelectionChanged}
                        sizePerPageList={sizePerPageList}
                        groupButton={groupButton}
                        onSortChanged={onSortChanged}
                        components={components}
                        onFirstDataRendered={onFirstDataRendered}
                    />
                </div>
                <EditShop
                    data={shopSelect}
                    onClose={() => setShopSelect(null)}
                    onSubmit={() => {
                        getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, sort_by, sort_order });
                    }}
                />
                <ModalMember
                    isOpen={!!editMember}
                    data={editMember}
                    onClose={() => setEditMember(null)}
                    onSubmit={(data) =>
                        updateMemberShop({
                            ...data,
                            page,
                            sizePerPage,
                            searchText,
                            filter,
                            sort_by,
                            sort_order,
                        })
                    }
                />
                <ViewSizeChart isOpen={!!viewChart} data={viewChart} onClose={() => setViewChart(null)} />
            </div>
        </div>
    );
};

const mapStateToProps = ({ shop, user }) => ({ shop, user });
const mapDispatchToProps = {
    getShops,
    deleteShop,
    refreshToken,
    getStaffs,
    updateMemberShop,
    FormatterStatus,
    cancelDeleteShop,
    formatterCreateAt,
    syncInfomation,
    syncOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
