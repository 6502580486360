import { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
    getProducts,
    deleteProduct,
    deleteUpdateCategory,
    shareProductById,
    duplicateProduct,
    changeProductByTemplate,
    saveEditProduct,
    changePublicProduct,
} from "actions/productActions";
import { productScheduleMulti } from "actions/productScheduleActions";
import { getStaffs } from "actions/teamActions";
import { getShops } from "actions/shopActions";
import { getProductTemplate } from "actions/productTemplateAction";
import { getCategoriesProduct } from "actions/categoryProductAction";
import { ReactTable, ReactTableDataGrid } from "components/Table";
import { ListDateType } from "helper/constant";
import { formatterCreateAt, convertTZ, subStringText, countString } from "components/Function";
import SelectBox from "components/elements/selectBox";
import Loading from "components/Loading";
import ModalMoveCategories from "./modalMoveCategories";
import ModalScheduleMulti from "./modalScheduleMulti";
import ModalChangeByTemplate from "./modalChangeByTemplate";
import ModalPublicProduct from "./modalPublicProduct";
import ModalShare from "./modalShare";
import Swal from "sweetalert2";
import { ModalSchedule } from "./modal";
import ReactImageMagnify from "react-image-magnify";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const ProductList = ({
    getProducts,
    deleteProduct,
    product,
    formatterCreateAt,
    getStaffs,
    productScheduleMulti,
    countString,
    getCategoriesProduct,
    deleteUpdateCategory,
    shareProductById,
    duplicateProduct,
    getProductTemplate,
    productTemplate,
    changeProductByTemplate,
    getShops,
    shop,
    saveEditProduct,
    changePublicProduct,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [dateType, setDateType] = useState("");
    const [products_selected, setProductsSelected] = useState([]);
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [productScheduleSelect, setProductScheduleSelect] = useState(null);
    // const [rowShow, setRowShow] = useState("");
    const { t } = useTranslation();
    const { list, totalsize, isLoading } = product;
    const { list: templates } = productTemplate;
    const { list: shops } = shop;

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    useEffect(() => {
        if (Object.keys(filter).length) {
            getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
        }
    }, [getProducts, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    useEffect(() => {
        getProductTemplate({ page: 1, sizeperpage: 9999 });
    }, [getProductTemplate]);

    useEffect(() => {
        getShops({ page: 1, sizeperpage: 1000 });
    }, [getShops]);

    useEffect(() => {
        getCategoriesProduct({ sizeperpage: 100 }).then((data) => {
            setCategories(
                [{ value: "NOTINALL", label: "Not In Category" }].concat(
                    data?.categoryProducts?.map((cate) => ({ value: cate?._id, label: cate.title })) || []
                )
            );
        });
    }, [getCategoriesProduct]);

    useEffect(() => {
        setPage(1);
        setSizePerPage(50);

        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start);
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date);
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date);
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    // const handleSchedule = (id) => {
    //     setRowShow(id)
    //     console.log(rowShow)
    //     Swal.fire(t("Success"), id, "success");

    // };

    const columns = [
        {
            field: 'checkbox',
            cellClass: "centered-cell",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 50,
        },
        {
            field: "_id",
            headerName: "",
            sort: true,
            editable: false,
            cellClass: "centered-cell",
            width: 10,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
        },
        {
            field: "main_image",
            headerName: "Main Images",
            cellClass: "centered-cell overflow-visible",
            sort: true,
            editable: false,
            width: 120,
            cellRenderer: ({ data: row }) => (
                <>
                    <div className="symbol symbol-50px">
                        <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: "",
                                    width: 50,
                                    height: 50,
                                    src: row.main_images?.[0] || require("components/assets/media/misc/image.png"),
                                },
                                largeImage: {
                                    src: row.main_images?.[0] || require("components/assets/media/misc/image.png"),
                                    width: 600,
                                    height: 600,
                                },
                                enlargedImageContainerDimensions: { width: "600%", height: "600%" },
                                enlargedImageContainerStyle: { zIndex: 999999 },
                                className: "cursor-pointer",
                                enlargedImageContainerClassName: "al-zoom-img-cus",
                                imageStyle: { zIndex: 9999999 },
                            }}
                        />
                    </div>
                </>
            ),
        },
        {
            field: "title",
            headerName: t("Title"),
            editable: true,
            sort: true,
            // width: 450,
            flex: 1,
            cellRenderer: ({ value: cell }) => (
                <Fragment>
                    <span data-tooltip-id="my-tooltip"
                        data-tooltip-content={cell}>{cell}</span>
                    <div className={cell?.length < 145 && cell?.length > 40 ? "text-success" : "text-danger"}>
                        Total: {cell.length} <span className="text-info">{countString(cell)}</span>
                    </div>
                </Fragment>
            ),
        },
        {
            field: "product_sku",
            headerName: t("SKU"),
            editable: false,
            sort: true,
            cellRenderer: ({ value: cell }) => <span data-tooltip-id="my-tooltip"
                data-tooltip-content={cell}>{cell}</span>,
        },
        {
            field: "ref_link",
            headerName: t("Ref Link"),
            editable: false,
            sort: true,
            cellRenderer: ({ value: cell }) => <Fragment><Link to={cell || "#"} target={cell ? "_blank" : "_self"}>{cell}</Link></Fragment>,
        },
        {
            field: "categories",
            headerName: t("Category"),
            editable: false,
            sort: true,
            width: 80,
            cellRenderer: ({ value: cell }) => <span data-tooltip-id="my-tooltip"
                data-tooltip-content={categories?.find((v, i) => v?.value === cell)?.label || ""}>{categories?.find((v, i) => v?.value === cell)?.label || ""}</span>,
        },
        {
            field: "msg",
            headerName: t("Message Upload"),
            editable: false,
            sort: true,
            width: 100,
            cellRenderer: ({ value: cell }) => <span data-tooltip-id="my-tooltip"
                data-tooltip-content={cell}>{cell}</span>
        },
        {
            field: "createdBy",
            headerName: t("Created By"),
            editable: false,
            sort: true,
            cellRenderer: ({ value: cell, data: row }) => (
                <>
                    {row.is_sharing === "" ? (
                        <span className="badge badge-light-dark badge-sm fs-xl">
                            <i className="ki-outline ki-information-3"></i> Private
                        </span>
                    ) : (
                        <span className="badge badge-light-success badge-sm fs-xl">
                            <i className="ki-outline ki-share"></i> Public
                        </span>
                    )}
                    {staffs?.find((v, i) => v?.value === cell)?.label}
                </>
            ),
        },
        {
            field: "created_at",
            headerName: t("Created At"),
            editable: false,
            sort: true,
            cellRenderer: ({ value: cell }) => <span data-tooltip-id="my-tooltip"
                data-tooltip-content={formatterCreateAt(cell)}>{formatterCreateAt(cell)}</span>,
        },
        {
            field: "-",
            headerName: t("Actions"),
            editable: false,
            width: 140,
            cellRenderer: ({ data: row }) => (
                <>
                    <Link
                        role="button"
                        to={`/admin/products/edit/${row._id}`}
                        className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Edit")}
                    >
                        <i className="ki-outline ki-notepad-edit fs-2"></i>
                    </Link>
                    {/* <button
                        className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Delete")}
                        onClick={() =>
                            deleteProduct(row._id).then((data) => {
                                if (data) getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
                            })
                        }
                    >
                        <i className="ki-outline ki-trash fs-2"></i>
                    </button> */}
                    <button
                        onClick={() => {
                            setProductScheduleSelect({
                                ...row,
                                page,
                                sizeperpage: sizePerPage,
                                search: searchText,
                                filter,
                            });
                        }}
                        className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={t("Schedule")}
                    >
                        <i className="ki-outline ki-calendar-add fs-2"></i>
                    </button>
                </>
            ),
        },
    ];

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const handleFilter = (e) => {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleEventDate = (start, end) => {
        setPage(1);
        setSizePerPage(50);

        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    const onClickDeleteProduct = () => {
        deleteProduct(products_selected, setLoading).then((data) => {
            if (data) getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
            return Swal.fire(t("Success"), t("Delete Success!"), "success");
        });
    };

    const onDuplicate = () => {
        duplicateProduct(products_selected).then((data) => {
            if (data) getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
            return Swal.fire(t("Success"), t("Duplicate Success!"), "success");
        });
    };

    const handleMoveProduct = async (data) => {
        await deleteUpdateCategory({ listId: products_selected, idCate: data }).then((data) => {
            if (data) getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
            return Swal.fire(t("Success"), t("Move Success!"), "success");
        });
    };

    const handleSchedule = async (data) => {
        var products = list?.filter((v, i) => products_selected.includes(v._id.toString()));
        await productScheduleMulti({ ...data, products, type: "system" }, setLoading);
    };

    const handlePublicProduct = async (is_sharing) => {
        await changePublicProduct({ listId: products_selected, is_sharing }).then((data) => {
            if (data) getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
            return Swal.fire(t("Success"), t("Change Success!"), "success");
        });
    };

    const handleChangeProductByTemplate = async (data) => {
        setLoading(true);
        await changeProductByTemplate({ listId: products_selected, template_id: data }).then((data) => {
            setLoading(false);
            if (data) getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
            return Swal.fire(t("Success"), t("Change Success!"), "success");
        });
    };

    const onSelectionChanged = (data) => {
        if (data.api) {
            const ids = data.api.getSelectedRows().map((d) => d._id);
            setProductsSelected(ids);
        }
    };

    // const removeSelect = (arr, value) => {
    //     var i = 0;
    //     while (i < arr.length) {
    //         if (arr[i] === value) {
    //             arr.splice(i, 1);
    //         } else {
    //             ++i;
    //         }
    //     }
    //     return arr;
    // };

    // const onSelect = (row, isSelect) => {
    //     var products_selected_tmp = products_selected;
    //     if (isSelect) {
    //         products_selected_tmp.push(row._id);
    //     } else {
    //         products_selected_tmp = removeSelect(products_selected_tmp, row._id);
    //     }
    //     var new_arr = JSON.stringify(products_selected_tmp);
    //     setProductsSelected(JSON.parse(new_arr));
    // };

    // const onSelectAll = (isSelect, row) => {
    //     var products_selected_tmp = products_selected;
    //     if (isSelect) {
    //         products_selected_tmp = row.map((v, i) => v._id);
    //     } else {
    //         products_selected_tmp = [];
    //     }
    //     setProductsSelected(products_selected_tmp);
    // };

    // const handleTableSaveCell = (oldValue, newValue, row, column) => {
    //     saveEditProduct({
    //         _id: row._id,
    //         [column.dataField]: newValue,
    //         page,
    //         sizeperpage: sizePerPage,
    //         search: searchText,
    //         filter,
    //     });
    // };

    const onCellValueChanged = ({ data, colDef, newValue, oldValue }) => {
        if (newValue !== oldValue) {
            saveEditProduct({
                _id: data._id,
                [colDef.field]: newValue,
                page,
                sizeperpage: sizePerPage,
                search: searchText,
                filter,
            });
        }
    }

    // const selectRow = {
    //     mode: "checkbox",
    //     clickToSelect: false,
    //     selected: products_selected,
    //     onSelect: onSelect,
    //     onSelectAll: onSelectAll,
    //     selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
    //         <div className="d-flex">
    //             <input
    //                 type="checkbox"
    //                 ref={(input) => {
    //                     if (input) {
    //                         input.indeterminate = indeterminate;
    //                         input.checked = checked;
    //                     }
    //                 }}
    //                 onChange={onChange}
    //             />
    //             <span style={{ marginLeft: "8px" }}>({products_selected.length})</span>
    //         </div>
    //     ),
    // };

    const onClickShareData = async (email) => {
        setLoading(true);
        await shareProductById(email, products_selected).then(async (data) => {
            if (data?.status === 200) {
                setLoading(false);
                return Swal.fire(t("Success"), t("Shared Success!"), "success");
            } else {
                setLoading(false);
                return Swal.fire(t("Error"), t("Share Failed!"), "error");
            }
        });
    };

    const groupButton = () => {
        return (
            <>
                <div className="mb-5">
                    <Link to="/admin/products/tools" className="btn btn-dark btn-sm me-2 mb-2">
                        <i className="ki-outline ki-setting fs-2"></i> {t("Tools Listing")}
                    </Link>
                    <Link to="/admin/products/create" className="btn btn-primary btn-sm me-2 mb-2">
                        <i className="ki-outline ki-plus fs-2"></i> {t("Add Product")}
                    </Link>
                    <button
                        onClick={() => {
                            onClickDeleteProduct();
                        }}
                        type="button"
                        className="btn btn-danger btn-sm me-2 mb-2"
                    >
                        <i className="ki-outline ki-cross-circle fs-2"></i> {t("Delete")}
                    </button>
                    <ModalMoveCategories onSubmit={handleMoveProduct} categories={categories} />
                    <ModalShare
                        onSubmit={(data) => {
                            onClickShareData(data);
                        }}
                    />
                    <button
                        onClick={() => {
                            onDuplicate();
                        }}
                        type="button"
                        className="btn btn-warning btn-sm me-2 mb-2"
                    >
                        <i className="ki-outline ki-some-files fs-2"></i> {t("Duplicate")}
                    </button>
                    <ModalChangeByTemplate
                        onSubmit={handleChangeProductByTemplate}
                        templates={templates?.map((v, i) => ({ value: v?._id, label: v.title }))}
                    />
                    <ModalScheduleMulti
                        onSubmit={handleSchedule}
                        shops={shops?.map((v, i) => ({
                            value: v?._id,
                            label: `${v.name} - ${v.note}/${v.member_note}`,
                        }))}
                        products_selected={products_selected?.length}
                    />
                    <ModalPublicProduct onSubmit={handlePublicProduct} />
                    <br/>
                    <select
                        className="form-select basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2"
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder="Filter Used"
                        name="used"
                        onChange={handleFilter}
                    >
                        <option value="">Filter Used</option>
                        <option value="not_used">Not Used</option>
                        <option value="used">Used</option>
                    </select>
                    <select
                        className="form-select basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2"
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder="Sharing"
                        name="is_sharing"
                        onChange={handleFilter}
                    >
                        <option value="">Sharing...</option>
                        <option value="private">Private</option>
                        <option value="shared">Public</option>
                    </select>
                    {/* <SelectBox
                        options={staffs}
                        name="filter_staff"
                        className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2"
                        value={filter.staff || ""}
                        onChange={(data) => {
                            setFilter((prev) => ({
                                ...prev,
                                staff: data.value || "",
                            }));
                            setPage(1);
                        }}
                    /> */}
                    <Select
                        isClearable
                        name="filter_staff"
                        options={staffs}
                        value={staffs.filter(({ value }) => value === filter.staff)}
                        className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table"
                        classNamePrefix="select"
                        onChange={(data) => {
                            setFilter((prev) => ({
                                ...prev,
                                staff: data?.value || "",
                            }));
                            setPage(1);
                        }}
                        placeholder={t(`Select a staff ... `)}
                    />
                    {/* <SelectBox
                        options={categories}
                        name="filter_categories"
                        className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2"
                        value={filter.categories || ""}
                        onChange={(data) => {
                            setFilter((prev) => ({
                                ...prev,
                                categories: data.value || "",
                            }));
                            setPage(1);
                        }}
                    /> */}
                    <Select
                        isClearable
                        name="filter_categories"
                        options={categories}
                        value={categories.filter(({ value }) => value === filter.categories)}
                        className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table"
                        classNamePrefix="select"
                        onChange={(data) => {
                            setFilter((prev) => ({
                                ...prev,
                                categories: data?.value || "",
                            }));
                            setPage(1);
                        }}
                        placeholder={t(`Select a category ... `)}
                    />
                    <Select
                        name="dateType"
                        options={ListDateType}
                        className="basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2"
                        classNamePrefix="select"
                        value={ListDateType.filter(({ value }) => value === dateType)}
                        onChange={(e) => {
                            setDateType(e?.value || "");
                        }}
                        placeholder="Select a date ... "
                    />
                    {dateType === "custom" && filter?.dateStart && filter?.dateEnd ? (
                        <DateRangePicker
                            onCallback={handleEventDate}
                            initialSettings={{
                                startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                showDropdowns: true,
                                locale: { format: "DD/MM/YYYY" },
                            }}
                        >
                            <input type="text" className="form-control al-form-control-daterangepicker mr-1" />
                        </DateRangePicker>
                    ) : (
                        ""
                    )}
                </div>
            </>
        );
    };

    const onTableChanged = ({ page, limit, text }) => {
        if (limit) setSizePerPage(limit);
        if (page) setPage(page);
        if (typeof text === "string") setSearchText(text);
    };

    const onFirstDataRendered = (params) => {
        params.columnApi.autoSizeColumns(["main_image", "title", "product_sku", "categories", "msg", "createdBy", "created_at", "-"]);
    };

    return (
        <div className="card min-w-full">
            <Loading isLoading={isLoading || loading} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    {/* <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                        isSelectRow={true}
                        selectRow={selectRow}
                        handleBeforeSaveCell={handleTableSaveCell}
                        isCellEdit={true}
                    /> */}
                    <ReactTableDataGrid
                        tableName="productSystem"
                        idProperty="_id"
                        columns={columns}
                        rowData={list}
                        gridOptions={{
                            rowHeight: 80,
                            reactiveCustomComponents: true,
                        }}
                        paginationPageSize={sizePerPage}
                        totalSize={totalSize}
                        onTableChanged={onTableChanged}
                        emptyText={t("No data!")}
                        rowSelection="multiple"
                        onSelectionChanged={onSelectionChanged}
                        sizePerPageList={sizePerPageList}
                        onCellValueChanged={onCellValueChanged}
                        onFirstDataRendered={onFirstDataRendered}
                        groupButton={groupButton}
                    />
                </div>
                <ModalSchedule
                    data={productScheduleSelect}
                    shops={shops?.map((v, i) => ({ value: v?._id, label: `${v.name} (${v.note} - ${v.member_note})` }))}
                    onClose={() => setProductScheduleSelect(null)}
                    onSubmit={() => {
                        getProducts({ page, sizeperpage: sizePerPage, search: searchText, filter });
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ product, productTemplate, shop }) => ({ product, productTemplate, shop });
const mapDispatchToProps = {
    getProducts,
    deleteProduct,
    formatterCreateAt,
    getStaffs,
    subStringText,
    getCategoriesProduct,
    deleteUpdateCategory,
    shareProductById,
    duplicateProduct,
    getProductTemplate,
    changeProductByTemplate,
    getShops,
    countString,
    productScheduleMulti,
    saveEditProduct,
    changePublicProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
