import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Loading from "components/Loading";
import ShopList from "./shops";
import ProductList from "./product";
import { sleep } from "components/Function";
import _ from "lodash";
import { uploadProductToTiktokTool, uploadProductToTiktokToolv2, uploadProductToTiktokToolv3 } from "actions/productActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { productScheduleMulti2 } from "actions/productScheduleActions";


const ProductTools = ({ uploadProductToTiktokTool, uploadProductToTiktokToolv2, productScheduleMulti2 }) => {
    const { t } = useTranslation();
    const [shops_selected, setShopsSelected] = useState([]);
    const [products_selected, setProductsSelected] = useState([]);
    const [list_products_data, setListProductsData] = useState([]);
    const [result, setResult] = useState([]);
    const [timeDelay, setTimeDelay] = useState(0);
    const [timeDelaySaveMode, setTimeDelaySaveMode] = useState(0);
    const [type, setType] = useState("type1");
    const [saveMode, setSaveMode] = useState("SCHEDULE");
    const [quantity, setQuantity] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [upload_at, setUploadAt] = useState(new Date());

    const handleSubmit = async () => {
        if (!products_selected.length || !shops_selected?.length)
            return Swal.fire(t("Error"), t("Products and Shops is required!"), "error");
        setResult([]);
        setIsLoading(true);
        if (type === "type3") {
            var count = 0;
            var containMap = [];
            if (products_selected.length > shops_selected.length) {
                for (let i = 0; i < products_selected.length; i++) {
                    containMap.push({ shopId: shops_selected[count], productId: products_selected[i] })
                    count++;
                    if (!shops_selected?.[count]) {
                        count = 0;
                    }
                }
            } else {
                for (let i = 0; i < shops_selected.length; i++) {
                    containMap.push({ productId: products_selected[count], shopId: shops_selected[i] })
                    count++;
                    if (!products_selected?.[count]) {
                        count = 0;
                    }
                }
            }
            var groupShop = Object?.entries(_.groupBy(containMap, "shopId"))?.map((v, o) => v[1]) || [];
            Promise.all(groupShop?.map(async (items, iv) => {
                await sleep(iv * 500)
                return new Promise(async (resolve, reject) => {
                    if (+timeDelay === 0) {
                        Promise.all(items?.map(async (obj, ic) => {
                            await sleep(ic * 500)
                            return new Promise(async (resolve2, reject2) => {
                                await uploadProductToTiktokTool(obj.productId, obj.shopId, saveMode, timeDelaySaveMode)
                                    .then((data) => {
                                        setResult((prev) => {
                                            const dataRs = [...prev, { ...data, productId: obj.productId, shopId: obj.shopId }];
                                            return dataRs;
                                        });
                                        resolve2('ok');
                                    })
                                    .catch((err) => {
                                        setResult((prev) => {
                                            const dataRs = [...prev, { productId: obj.productId, shopId: obj.shopId }];
                                            return dataRs;
                                        });
                                        resolve2('ok');
                                    });

                            });
                        })).then(async (dat) => {
                            resolve('ok');
                        })
                    } else {
                        for (let i = 0; i < items.length; i++) {
                            let obj = items[i];
                            await uploadProductToTiktokTool(obj.productId, obj.shopId, saveMode, timeDelaySaveMode)
                                .then((data) => {
                                    setResult((prev) => {
                                        const dataRs = [...prev, { ...data, productId: obj.productId, shopId: obj.shopId }];
                                        return dataRs;
                                    });
                                })
                                .catch((err) => {
                                    setResult((prev) => {
                                        const dataRs = [...prev, { productId: obj.productId, shopId: obj.shopId }];
                                        return dataRs;
                                    });

                                });
                            await sleep(+timeDelay * 1000 + Math.random() * 60000)
                        }
                        resolve('ok');
                    }

                });
            })).then(async (dat) => {
                setIsLoading(false);
                setProductsSelected([]);
                // setShopsSelected([]);
                return await Swal.fire(t("Success"), t("Schedule to TikTok Success. Check tab Product Schedule!"), "success");
                // return window.location.href = "/admin/product-schedule";
            })
        } else {
            await uploadProductToTiktokToolv2(products_selected, shops_selected).then((data) => {
                const productSystem = data?.data?.productSystem;
                const productFreeze = data?.data?.productFreeze;
                Promise.all(shops_selected.map(async (shopId, iv) => {
                    await sleep(iv * 500)
                    return new Promise(async (resolve, reject) => {
                        const productByShop = productSystem?.filter((p) => p.shopId === shopId)?.map((v) => v.sourceId);
                        const productNotShop = products_selected?.filter((p) => !productByShop?.includes(p) && !productFreeze?.includes(p));
                        var list_product = _.sampleSize(productNotShop, quantity);
                        if (type === "type1") {
                            list_product = productNotShop;
                        }
                        if (+timeDelay === 0) {
                            Promise.all(list_product.map(async (productId, ic) => {
                                await sleep(ic * 500)
                                return new Promise(async (resolve2, reject2) => {
                                    await uploadProductToTiktokTool(productId, shopId, saveMode, timeDelaySaveMode)
                                        .then((data) => {
                                            setResult((prev) => {
                                                const dataRs = [...prev, { ...data, productId, shopId }];
                                                return dataRs;
                                            });
                                            resolve2('ok');
                                        })
                                        .catch((err) => {
                                            setResult((prev) => {
                                                const dataRs = [...prev, { productId, shopId }];
                                                return dataRs;
                                            });
                                            resolve2('ok');
                                        });

                                });
                            })).then(async (dat) => {
                                resolve('ok');
                            })
                        } else {
                            for (let i = 0; i < list_product.length; i++) {
                                let productId = list_product[i];
                                await uploadProductToTiktokTool(productId, shopId, saveMode, timeDelaySaveMode)
                                    .then((data) => {
                                        setResult((prev) => {
                                            const dataRs = [...prev, { ...data, productId, shopId }];
                                            return dataRs;
                                        });
                                    })
                                    .catch((err) => {
                                        setResult((prev) => {
                                            const dataRs = [...prev, { productId, shopId }];
                                            return dataRs;
                                        });

                                    });
                                await sleep(+timeDelay * 1000 + Math.random() * 60000)
                            }
                            resolve('')
                        }

                    });
                })).then(async (data) => {
                    setIsLoading(false);
                    setProductsSelected([]);
                    // setShopsSelected([]);
                    return await Swal.fire(t("Success"), t("Schedule to TikTok Success. Check tab Product Schedule!"), "success");
                    // return window.location.href = "/admin/product-schedule";
                });
            })
        }
    };

    const handleSchedule = async () => {
        if (!products_selected.length || !shops_selected?.length)
            return Swal.fire(t("Error"), t("Products and Shops is required!"), "error");
        setResult([]);
        setIsLoading(true);
        if (type === "type3") {
            var count = 0;
            var containMap = [];
            if (products_selected.length > shops_selected.length) {
                for (let i = 0; i < products_selected.length; i++) {
                    containMap.push({ shopId: shops_selected[count], productId: products_selected[i] })
                    count++;
                    if (!shops_selected?.[count]) {
                        count = 0;
                    }
                }
            } else {
                for (let i = 0; i < shops_selected.length; i++) {
                    containMap.push({ productId: products_selected[count], shopId: shops_selected[i] })
                    count++;
                    if (!products_selected?.[count]) {
                        count = 0;
                    }
                }
            }
            var groupShop = Object?.entries(_.groupBy(containMap, "shopId"))?.map((v, o) => v[1]) || [];
            Promise.all(groupShop?.map(async (items, iv) => {
                await sleep(iv * 500)
                return new Promise(async (resolve, reject) => {
                    Promise.all(items?.map(async (obj, j) => {
                        return new Promise(async (resolve2, reject2) => {
                            var products = list_products_data?.find((v, i) => v?._id?.toString() === obj?.productId) || {};
                            var shopId = obj?.shopId || "";
                            var date_at = new Date(Date.parse(upload_at) + j * timeDelay * 1000);
                            if (products?._id !== "" && shopId !== "") {
                                await productScheduleMulti2({ title: products.title, _id: obj?.productId, main_images: products?.main_images?.[0], shop: [shopId], upload_at: date_at, timeSleep: timeDelay, type: "system", saveMode })

                            }
                            resolve2()
                        });
                    })).then(async (dat) => {
                        resolve('ok');
                    })

                });
            })).then(async (dat) => {
                setIsLoading(false);
                setProductsSelected([]);
                // setShopsSelected([]);
                return await Swal.fire(t("Success"), t("Schedule to TikTok Success. Check tab Product Schedule!"), "success");
                // return window.location.href = "/admin/product-schedule";
            })
        } else {
            await uploadProductToTiktokToolv2(products_selected, shops_selected).then((data) => {
                const productSystem = data?.data?.productSystem;
                const productFreeze = data?.data?.productFreeze;
                Promise.all(shops_selected.map(async (shopId, iv) => {
                    await sleep(iv * 500)
                    return new Promise(async (resolve, reject) => {
                        const productByShop = productSystem?.filter((p) => p.shopId === shopId)?.map((v) => v.sourceId);
                        const productNotShop = products_selected?.filter((p) => !productByShop?.includes(p) && !productFreeze?.includes(p));
                        var list_product = _.sampleSize(productNotShop, quantity);
                        if (type === "type1") {
                            list_product = productNotShop;
                        }
                        Promise.all(list_product.map(async (productId, j) => {
                            return new Promise(async (resolve2, reject2) => {
                                var products = list_products_data?.find((v, i) => v?._id?.toString() === productId) || {};
                                // var shopId = obj?.shopId || "";
                                var date_at = new Date(Date.parse(upload_at) + j * timeDelay * 1000);
                                if (products?._id !== "" && shopId !== "") {
                                    const data = await productScheduleMulti2({ title: products.title, _id: productId, main_images: products?.main_images?.[0], shop: [shopId], upload_at: date_at, timeSleep: timeDelay, type: "system", saveMode })
                                    resolve2(data.status === 200)
                                }
                                resolve2()
                            });
                        })).then(async (dat) => {
                            resolve(dat);
                        })

                    });
                })).then(async (data) => {
                    const countError = data.reduce((result, arr) => {
                        const errors = arr.filter((a) => !a).length;
                        if (errors) result = result + errors;
                        return result;
                    }, 0)
                    let messageError = "";
                    if (countError) messageError = ` (${countError} item error)`

                    setIsLoading(false);
                    setProductsSelected([]);
                    // setShopsSelected([]);
                    return await Swal.fire(t("Success"), t(`Schedule to TikTok Success${messageError}. Check tab Product Schedule!`), "success");
                    // return window.location.href = "/admin/product-schedule";
                });
            })
        }
    };

    const handleSubmitv3 = async () => {
        if (!products_selected.length || !shops_selected?.length)
            return Swal.fire(t("Error"), t("Products and Shops is required!"), "error");
        setResult([]);
        setIsLoading(true);
        if (type === "type3") {
            var count = 0;
            var containMap = [];
            if (products_selected.length > shops_selected.length) {
                for (let i = 0; i < products_selected.length; i++) {
                    containMap.push({ shopId: shops_selected[count], productId: products_selected[i] })
                    count++;
                    if (!shops_selected?.[count]) {
                        count = 0;
                    }
                }
            } else {
                for (let i = 0; i < shops_selected.length; i++) {
                    containMap.push({ productId: products_selected[count], shopId: shops_selected[i] })
                    count++;
                    if (!products_selected?.[count]) {
                        count = 0;
                    }
                }
            }
            var groupShop = Object?.entries(_.groupBy(containMap, "shopId"))?.map((v, o) => v[1]) || [];
            Promise.all(groupShop?.map(async (items, iv) => {
                await sleep(iv * 1000)
                return new Promise(async (resolve, reject) => {
                    if (+timeDelay === 0) {
                        Promise.all(items?.map(async (obj, ic) => {
                            await sleep(ic * 500)
                            return new Promise(async (resolve2, reject2) => {
                                await uploadProductToTiktokToolv3(obj.productId, obj.shopId, saveMode, timeDelaySaveMode)
                                    .then((data) => {
                                        setResult((prev) => {
                                            const dataRs = [...prev, { ...data, productId: obj.productId, shopId: obj.shopId }];
                                            return dataRs;
                                        });
                                        resolve2('ok');
                                    })
                                    .catch((err) => {
                                        setResult((prev) => {
                                            const dataRs = [...prev, { productId: obj.productId, shopId: obj.shopId }];
                                            return dataRs;
                                        });
                                        resolve2('ok');
                                    });

                            });
                        })).then(async (dat) => {
                            resolve('ok');
                        })
                    } else {
                        for (let i = 0; i < items.length; i++) {
                            let obj = items[i];
                            await uploadProductToTiktokToolv3(obj.productId, obj.shopId, saveMode, timeDelaySaveMode)
                                .then((data) => {
                                    setResult((prev) => {
                                        const dataRs = [...prev, { ...data, productId: obj.productId, shopId: obj.shopId }];
                                        return dataRs;
                                    });
                                })
                                .catch((err) => {
                                    setResult((prev) => {
                                        const dataRs = [...prev, { productId: obj.productId, shopId: obj.shopId }];
                                        return dataRs;
                                    });

                                });
                            await sleep(+timeDelay * 1000 + Math.random() * 60000)
                        }
                        resolve('ok');
                    }

                });
            })).then(async (dat) => {
                setIsLoading(false);
                setProductsSelected([]);
                // setShopsSelected([]);
                return await Swal.fire(t("Success"), t("Schedule to TikTok Success. Check tab Product Schedule!"), "success");
                // return window.location.href = "/admin/product-schedule";
            })
        } else {
            await uploadProductToTiktokToolv2(products_selected, shops_selected).then((data) => {
                const productSystem = data?.data?.productSystem;
                const productFreeze = data?.data?.productFreeze;
                Promise.all(shops_selected.map(async (shopId, iv) => {
                    await sleep(iv * 1000)
                    return new Promise(async (resolve, reject) => {
                        const productByShop = productSystem?.filter((p) => p.shopId === shopId)?.map((v) => v.sourceId);
                        const productNotShop = products_selected?.filter((p) => !productByShop?.includes(p) && !productFreeze?.includes(p));
                        var list_product = _.sampleSize(productNotShop, quantity);
                        if (type === "type1") {
                            list_product = productNotShop;
                        }
                        if (+timeDelay === 0) {
                            Promise.all(list_product.map(async (productId, ic) => {
                                await sleep(ic * 500)
                                return new Promise(async (resolve2, reject2) => {
                                    await uploadProductToTiktokToolv3(productId, shopId, saveMode, timeDelaySaveMode)
                                        .then((data) => {
                                            setResult((prev) => {
                                                const dataRs = [...prev, { ...data, productId, shopId }];
                                                return dataRs;
                                            });
                                            resolve2('ok');
                                        })
                                        .catch((err) => {
                                            setResult((prev) => {
                                                const dataRs = [...prev, { productId, shopId }];
                                                return dataRs;
                                            });
                                            resolve2('ok');
                                        });

                                });
                            })).then(async (dat) => {
                                resolve('ok');
                            })
                        } else {
                            for (let i = 0; i < list_product.length; i++) {
                                let productId = list_product[i];
                                await uploadProductToTiktokToolv3(productId, shopId, saveMode, timeDelaySaveMode)
                                    .then((data) => {
                                        setResult((prev) => {
                                            const dataRs = [...prev, { ...data, productId, shopId }];
                                            return dataRs;
                                        });
                                    })
                                    .catch((err) => {
                                        setResult((prev) => {
                                            const dataRs = [...prev, { productId, shopId }];
                                            return dataRs;
                                        });

                                    });
                                await sleep(+timeDelay * 1000 + Math.random() * 60000)
                            }
                            resolve('')
                        }

                    });
                })).then(async (data) => {
                    setIsLoading(false);
                    setProductsSelected([]);
                    // setShopsSelected([]);
                    return await Swal.fire(t("Success"), t("Schedule to TikTok Success. Check tab Product Schedule!"), "success");
                    // return window.location.href = "/admin/product-schedule";
                });
            })
        }
    };

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className="row">
                <div className="col-md-12">
                    <ShopList shops_selected={shops_selected} setShopsSelected={setShopsSelected} />
                </div>
                <div className="col-md-12">
                    <ProductList products_selected={products_selected} setProductsSelected={setProductsSelected} setListProductsData={setListProductsData} />
                </div>
                <div className="col-md-12">
                    <div className="card mb-5 mb-xl-10">
                        <div className="card-body p-9">
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Type")}</span>
                                </label>
                                <select
                                    className="form-select"
                                    data-control="select2"
                                    data-hide-search="true"
                                    data-placeholder="type"
                                    name="type"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <option value="type1">Type 1 (ALL Product to ALL Shop)</option>
                                    <option value="type2">Type 2 (Random Product Quantity to Shop)</option>
                                    <option value="type3">Type 3 (Only 1 Product in 1 Shop)</option>
                                </select>
                            </div>
                            {type === "type2" && <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Quantity")}</span>
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder={t("Quantity")}
                                    name="quantity"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                />
                            </div>}
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Time delay (second)")}</span>
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder={t("Time delay + 1-60s")}
                                    name="timeDelay"
                                    value={timeDelay}
                                    onChange={(e) => setTimeDelay(e.target.value)}
                                />
                            </div>
                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">{t("Save Mode")}</span>
                                </label>
                                <select
                                    className="form-select"
                                    data-control="select2"
                                    data-hide-search="true"
                                    data-placeholder="type"
                                    name="saveMode"
                                    value={saveMode}
                                    onChange={(e) => setSaveMode(e.target.value)}
                                >
                                    {/* <option value="LISTING">LISTING</option>
                                    <option value="AS_DRAFT">AS_DRAFT</option>
                                    <option value="OTHER">AS_DRAFT to LISTING</option> */}
                                    <option value="SCHEDULE">SCHEDULE</option>
                                    <option value="SCHEDULE_DAFT">SCHEDULE DAFT</option>
                                </select>
                            </div>
                            {saveMode === "OTHER" && <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="">{t("Delay AS_DRAFT to LISTING (second)")}</span>
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder={t("Time delay + 1-60s")}
                                    name="timeDelaySaveMode"
                                    value={timeDelaySaveMode}
                                    onChange={(e) => setTimeDelaySaveMode(e.target.value)}
                                />
                            </div>}
                            {saveMode === "SCHEDULE" || saveMode === "SCHEDULE_DAFT" ? <>
                                <div className="form-group mb-5">
                                    <label>Start Upload At</label>
                                    <div className="al-form-input-date">
                                        <DatePicker
                                            showTimeSelect
                                            timeIntervals={1}
                                            dateFormat="dd/MM/y HH:mm:ss"
                                            timeFormat="HH:mm"
                                            className="form-control w-100"
                                            selected={upload_at}
                                            onChange={setUploadAt}
                                        />
                                    </div>
                                </div>
                                <div className="align-items-center d-flex">
                                    <button onClick={handleSchedule} type="submit" className="btn btn-primary">
                                        Schedule Now
                                    </button>
                                </div>
                            </> : <div className="align-items-center d-flex">
                                <button onClick={handleSubmit} type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>}

                            {!!result?.length && (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Shop</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Message</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {result.map((v, k) => (
                                            <tr key={k}>
                                                <th scope="row">{k + 1}</th>
                                                <td>{v.productId}</td>
                                                <td>{v.shopId}</td>
                                                <td>{v?.status === 200 ? "success" : "fail"}</td>
                                                <td>{v?.message || "Something"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    uploadProductToTiktokTool,
    uploadProductToTiktokToolv2,
    productScheduleMulti2,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTools);
