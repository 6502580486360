import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalUpload({ onSubmit }) {
    const [activity_price_amount, setActivityPriceAmount] = useState(0);
    const [activity_price_amount_sold, setActivityPriceAmountSold] = useState(0);
    const [time, setTime] = useState(3);
    const [minOrderFls, setMinOrderFls] = useState(3);
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const onChange = (e) => {
        if (e.target.name === "activity_price_amount") {
            setActivityPriceAmount(e.target.value);
        }
        if (e.target.name === "activity_price_amount_sold") {
            setActivityPriceAmountSold(e.target.value);
        }
        if (e.target.name === "time") {
            setTime(e.target.value);
        }
        if (e.target.name === "minOrderFls") {
            setMinOrderFls(e.target.value);
        }
    }

    const onClose = () => {
        setIsOpen(false);
    };

    const handleSubmit = () => {
        onSubmit({ activity_price_amount, activity_price_amount_sold, time, minOrderFls });
        onClose();
    };

    return (
        <Fragment>
            <button
                className="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
                onClick={() => setIsOpen(true)}
            >
                <i className="ki-outline ki-cloud-download"></i>
                {t("Change Price Amount")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Support for Flash Sale")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="row mb-5">
                        <div className="col form-group">
                            <label>Activity Price Amount Normal</label>
                            <div className="al-form-input-date">

                                <input type="number" className="form-control w-100" min={0} placeholder="0 second" name="activity_price_amount" value={activity_price_amount} onChange={(e) => {
                                    onChange(e);
                                }} />
                            </div>
                        </div>
                        <div className="col form-group">
                            <label>Activity Price Amount Sold</label>
                            <div className="al-form-input-date">

                                <input type="number" className="form-control w-100" min={0} placeholder="0 second" name="activity_price_amount_sold" value={activity_price_amount_sold} onChange={(e) => {
                                    onChange(e);
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col form-group">
                            <label>Time Expried</label>
                            <div className="al-form-input-date">

                                <input type="number" className="form-control w-100" min={1} placeholder="1 day" name="time" value={time} onChange={(e) => {
                                    onChange(e);
                                }} />
                            </div>
                        </div>
                        <div className="col form-group">
                            <label>Min Order Fls</label>
                            <div className="al-form-input-date">

                                <input type="number" className="form-control w-100" min={1} placeholder="1 day" name="minOrderFls" value={minOrderFls} onChange={(e) => {
                                    onChange(e);
                                }} />
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpload);
