import { connect } from "react-redux";
import Analytic from "./analytic";
import Main from "./Main";

function Home(props) {
    const { role } = props.user.user;

    return [0,1,2].includes(role) ? <Analytic isAdmin={role === 1 || role === 0} /> : <Main />;
}

const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
