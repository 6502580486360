import { useState, useCallback, useEffect } from "react";
import { t } from "i18next";
import { AgGridReact } from "ag-grid-react";
import ReactPaginate from "react-paginate";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import { CheckboxDropdown } from "./elements/dropdownCheckbox";
import { parseJson, stringifyJson, sortArrByCondition } from "./Function";
import Loading from "./Loading";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
const { SearchBar } = Search;

const SearchBarCustom = (props) => {
    var input;
    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            props.onSearch(input.value);
        }
    };

    return (
        <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
                {t("Enter to search this table")}
            </span>
            <input
                id="search-bar-0"
                type="text"
                aria-labelledby="search-bar-0-label"
                className="form-control "
                placeholder={t("Enter to search...")}
                ref={(n) => (input = n)}
                onKeyDown={(e) => {
                    handleKeyDown(e);
                }}
            />
        </label>
    );
};

const ReactTable = (props) => {
    var {
        keyField = "_id",
        data,
        columns,
        groupButton,
        handleTableChange,
        page,
        sizePerPage,
        totalSize,
        expandRow,
        alClassName,
        isSearchBar = true,
        isCellEdit = false,
        handleBeforeSaveCell,
        isSelectRow = false,
        selectRow,
        remote = { search: true, pagination: true, sort: false },
        sizePerPageList,
        defaultSorted,
        isSearchBarQuick = false,
    } = props;

    return (
        <ToolkitProvider keyField={keyField} data={data} columns={columns} search>
            {(props_table) => (
                <div>
                    {isSearchBarQuick ? <SearchBar {...props_table.searchProps} /> : ""}
                    {isSearchBar ? <SearchBarCustom {...props_table.searchProps} /> : ""}
                    {typeof groupButton === "function" ? groupButton() : ""}
                    {isSelectRow ? (
                        <BootstrapTable
                            classes={alClassName}
                            remote={remote}
                            {...props_table.baseProps}
                            pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            onTableChange={handleTableChange}
                            expandRow={expandRow ? expandRow : {}}
                            cellEdit={
                                isCellEdit
                                    ? cellEditFactory({
                                          mode: "click",
                                          blurToSave: true,
                                          beforeSaveCell: handleBeforeSaveCell,
                                      })
                                    : {}
                            }
                            wrapperClasses="table-responsive"
                            noDataIndication={() => "No data to display"}
                            striped
                            hover
                            condensed
                            selectRow={selectRow}
                        />
                    ) : (
                        <BootstrapTable
                            classes={alClassName}
                            remote={remote}
                            {...props_table.baseProps}
                            pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            onTableChange={handleTableChange}
                            expandRow={expandRow ? expandRow : {}}
                            cellEdit={
                                isCellEdit
                                    ? cellEditFactory({
                                          mode: "click",
                                          blurToSave: true,
                                          beforeSaveCell: handleBeforeSaveCell,
                                      })
                                    : {}
                            }
                            wrapperClasses="table-responsive"
                            noDataIndication={() => "No data to display"}
                            striped
                            hover
                            condensed
                        />
                    )}
                </div>
            )}
        </ToolkitProvider>
    );
};

const ReactTableDataGrid = (props) => {
    const {
        idProperty = "_id",
        tableName = "",
        columns,
        paginationPageSize = 20,
        emptyText,
        height = 0,
        isSearchText = true,
        totalSize = 0,
        sizePerPageList = [],
        groupButton = () => {},
        ...other
    } = props;
    const gridStyle = {
        minHeight: "calc(100vh - 250px)",
        textAlign: "center",
    };
    const getColumnLocal = () => {
        const cLocal = parseJson(localStorage.getItem(`grid_${tableName}_column`));
        const cPosition = parseJson(localStorage.getItem(`grid_${tableName}_column_position`));
        var index = cLocal?.indexOf("checkbox");
        if (index > -1) {
            var [checkbox] = cLocal.splice(index, 1);
            cLocal.unshift(checkbox);
        } else {
            cLocal?.push("checkbox");
        }
        var index = cPosition?.indexOf("checkbox");
        if (index > -1) {
            var [checkbox] = cPosition.splice(index, 1);
            cPosition.unshift(checkbox);
        } else {
            cPosition?.push("checkbox");
        }
        if (tableName && cLocal) {
            const result = columns.filter((c) => cLocal.includes(c.field) || !c.field);
            return sortArrByCondition(result, cPosition);
        }

        return sortArrByCondition(columns, cPosition);
    };
    const pageCount = totalSize ? Math.ceil(totalSize / paginationPageSize) : 1;
    const [currentPage, setCurrentPage] = useState(0);
    const [tableColumns, setTableColumns] = useState([]);

    useEffect(() => {
        setTableColumns(getColumnLocal());
    }, [columns]);

    const handlePageSizeChange = (e) => {
        setCurrentPage(0);
        props?.onTableChanged?.({ limit: +e.target.value, page: 1 });
    };

    const handlePaginateChange = ({ selected }) => {
        props?.onTableChanged?.({ page: (selected || 0) + 1 });
        setCurrentPage(selected);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            props?.onTableChanged?.({ text: e.target.value, page: 1 });
            setCurrentPage(0);
        }
    };

    const handleChangeHiddenColumn = useCallback(
        (items) => {
            const cPosition = parseJson(localStorage.getItem(`grid_${tableName}_column_position`));
            const data = columns.filter((c) => items.includes(c.field) || !c.field);
            setTableColumns(sortArrByCondition(data, cPosition));
            localStorage.setItem(`grid_${tableName}_column`, stringifyJson(items));
        },
        [tableName, columns]
    );

    const handleColumnMoved = (event) => {
        if (event.finished && event.source === "uiColumnMoved") {
            localStorage.setItem(
                `grid_${tableName}_column_position`,
                stringifyJson(event.api.getColumnState().map((r) => r.colId))
            );
        }
    };
    
    const handleCellClick = (params) => {
        if (params.colDef.field === "checkbox") {
            params.node.setSelected(!params.node.isSelected());
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
                {groupButton()}
                <div className="mb-5 d-flex">
                    {isSearchText && (
                        <label className="search-label">
                            <span className="sr-only">Enter to search this table</span>
                            <input
                                onKeyDown={handleKeyDown}
                                type="text"
                                className="form-control "
                                placeholder="Enter to search..."
                            />
                        </label>
                    )}

                    <CheckboxDropdown
                        defaultData={parseJson(localStorage.getItem(`grid_${tableName}_column`)) || []}
                        onChangeData={handleChangeHiddenColumn}
                        items={columns.map((c) => ({ id: c.field, label: c.headerName }))}
                    />
                </div>
            </div>
            <div
                className={`ag-theme-alpine react-bootstrap-table table-responsive mostly-customized-scrollbar ${height ? "" : "al-cus-h-ag-table"}`}
                style={{ width: "100%", height: height ? height : "auto" }}
            >
                <Loading isLoading={props.loading} />
                <AgGridReact
                    {...other}
                    idProperty={idProperty}
                    columnDefs={tableColumns.length > 0 ? tableColumns : columns}
                    style={gridStyle}
                    onColumnMoved={handleColumnMoved}
                    noRowsOverlayComponent={() => (
                        <div className="no-rows-overlay">
                            <span>{emptyText || "No data available"}</span>
                        </div>
                    )}
                    react={{ reactiveCustomComponents: true }}
                    domLayout="normal"
                    suppressCellFocus={true}
                    enableCellTextSelection={true}
                    suppressRowVirtualisation={true}
                    suppressRowHoverHighlight={true}
                    // rowMultiSelectWithClick={true}
                    suppressRowClickSelection={true}
                    onCellClicked={handleCellClick}
                />
            </div>
            <div className="ag-theme-alpine">
                <div className="ag-grid-paginate-custom">
                    {Boolean(sizePerPageList?.length) && (
                        <span className="ag-paging-page-size">
                            <div
                                className="ag-picker-field ag-labeled ag-label-align-left ag-select"
                                role="presentation"
                            >
                                <div className="ag-label me-3" aria-hidden="false">
                                    Page Size:
                                </div>
                                <select
                                    onChange={handlePageSizeChange}
                                    className="form-select basic-multi-select al-custom-select-search-variants mr-1 mb-0 al-filter-table me-2"
                                    value={paginationPageSize}
                                >
                                    {sizePerPageList.map((data, key) => (
                                        <option key={key} value={data.value}>
                                            {data.text}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </span>
                    )}

                    {/* <div
                        className="ag-button ag-paging-button"
                        role="button"
                        aria-label="First Page"
                        tabIndex={0}
                        aria-disabled="true"
                    >
                        <span className="ag-icon ag-icon-first" unselectable="on" role="presentation" />
                    </div> */}
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={handlePaginateChange}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        renderOnZeroPageCount={null}
                        forcePage={currentPage}
                        nextLabel={
                            <div
                                className="ag-button ag-paging-button mt-1"
                                role="button"
                                aria-label="Next Page"
                                tabIndex={0}
                                aria-disabled="true"
                            >
                                <span className="ag-icon ag-icon-next" unselectable="on" role="presentation" />
                            </div>
                        }
                        previousLabel={
                            <div
                                className="ag-button ag-paging-button mt-1"
                                role="button"
                                aria-label="Previous Page"
                                tabIndex={0}
                                aria-disabled="true"
                            >
                                <span className="ag-icon ag-icon-previous" unselectable="on" role="presentation" />
                            </div>
                        }
                    />
                    {/* <div
                        className="ag-button ag-paging-button"
                        role="button"
                        aria-label="Last Page"
                        tabIndex={0}
                        aria-disabled="true"
                    >
                        <span className="ag-icon ag-icon-last" unselectable="on" role="presentation" />
                    </div> */}
                </div>
            </div>
        </>
    );
};

export { ReactTable, ReactTableDataGrid };
