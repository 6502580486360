import { useEffect, useState, useMemo } from "react";
import { ReactTableDataGrid } from "components/Table";
import { connect } from "react-redux";
import { getInfoTotal } from "actions/analyticActions";
import { chain } from "lodash";
import { formatterCreateAt, convertTZ } from "components/Function";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ListDateType } from "helper/constant";
import Select from "react-select";

const AdminTable = ({ getInfoTotal }) => {
    const [filter, setFilter] = useState({});
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [totalSize, setTotalSize] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [list, setList] = useState([]);
    const [dateType, setDateType] = useState("today");
    const [isLoading, setIsLoading] = useState([]);

    useEffect(() => {
        if (dateType === "") {
            setFilter((prev) => ({
                ...prev,
                dateStart: "",
                dateEnd: "",
            }));
        }
        if (dateType === "custom") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "today") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date();
            date_end = convertTZ(date_end, "Asia/Ho_Chi_Minh");
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "yesterday") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate());
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_week") {
            let date_start = new Date();
            date_start = convertTZ(date_start, "Asia/Ho_Chi_Minh");
            date_start.setDate(date_start.getDate() - 7 - date_start.getDay() + 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date_start);
            date_end.setDate(date_end.getDate() + 6);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_month") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setMonth(date.getMonth() - 1);
            let date_start = new Date(date.getFullYear(), date.getMonth(), 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "this_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        if (dateType === "last_year") {
            let date = new Date();
            date = convertTZ(date, "Asia/Ho_Chi_Minh");
            date.setFullYear(date.getFullYear() - 1);
            let date_start = new Date(date.getFullYear(), 0, 1);
            date_start.setHours(0, 0, 0, 0);

            let date_end = new Date(date.getFullYear(), 11, 31);
            date_end.setHours(23, 59, 59, 999);
            setFilter((prev) => ({
                ...prev,
                dateStart: date_start.getTime(),
                dateEnd: date_end.getTime(),
            }));
        }
        // eslint-disable-next-line
    }, [dateType]);

    useEffect(() => {
        if (Object.keys(filter)?.length) {
            setIsLoading(true);
            getInfoTotal({ filter: filter }).then((d) => {
                setList(d?.data?.data || []);
                setIsLoading(false);
            });
        }
    }, [getInfoTotal, filter]);

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalSize || 50,
        },
    ];

    const columns = [
        {
            field: "_id",
            width: 120,
            cellClass: "centered-cell",
            headerName: "",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
        },
        {
            field: "userName",
            cellClass: "centered-cell",
            headerName: "Username",
            flex: 1,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => row?.user?.email,
            comparator: (_cellA, _cellB, { data: valueA }, { data: valueB }) => {
                if (valueA?.user?.email < valueB?.user?.email) return -1;
                if (valueA?.user?.email > valueB?.user?.email) return 1;
                return 0;
            },
        },
        {
            field: "shopTotal",
            cellClass: "centered-cell",
            headerName: "Total Shop",
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                chain(row?.tiktokProducts)
                    .groupBy("shopId")
                    .map((value, key) => ({ key, value }))
                    ?.value()?.length || 0,
            comparator: (_cellA, _cellB, { data: valueA }, { data: valueB }) => {
                const a =
                    chain(valueA?.tiktokProducts)
                        .groupBy("shopId")
                        .map((value, key) => ({ key, value }))
                        ?.value()?.length || 0;

                const b =
                    chain(valueB?.tiktokProducts)
                        .groupBy("shopId")
                        .map((value, key) => ({ key, value }))
                        ?.value()?.length || 0;
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            },
        },
        {
            field: "totalProductImport",
            cellClass: "centered-cell",
            headerName: "Total import",
        },
        {
            field: "totalListing",
            width: 120,
            cellClass: "centered-cell",
            headerName: "Total Listing",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => row?.totalProductProductListing || 0,
            comparator: (_cellA, _cellB, { data: valueA }, { data: valueB }) => {
                if (valueA?.totalProductProductListing < valueB?.totalProductProductListing) return -1;
                if (valueA?.totalProductProductListing > valueB?.totalProductProductListing) return 1;
                return 0;
            },
        },
        {
            field: "totalActive",
            cellClass: "centered-cell",
            headerName: "Total Active",
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                row?.tiktokProducts.filter((i) => i.apiStatus === "ACTIVATE")?.length || 0,
            comparator: (_cellA, _cellB, { data: valueA }, { data: valueB }) => {
                const a = valueA?.tiktokProducts.filter((i) => i.apiStatus === "ACTIVATE")?.length;
                const b = valueB?.tiktokProducts.filter((i) => i.apiStatus === "ACTIVATE")?.length;
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            },
        },
    ];

    const onTableChanged = ({ page, limit, text }) => {
        if (limit) setSizePerPage(limit);
        if (page) setPage(page);
        if (typeof text === "string") setSearchText(text);
    };

    const handleEventDate = (start, end) => {
        let date_start = new Date(start);
        date_start.setHours(0, 0, 0, 0);

        let date_end = new Date(end);
        date_end.setHours(23, 59, 59, 999);
        setFilter((prev) => ({
            ...prev,
            dateStart: date_start.getTime(),
            dateEnd: date_end.getTime(),
        }));
    };

    return (
        <div className="mt-4 card card-body">
            <h3>Analytic Members</h3>
            <ReactTableDataGrid
                tableName="analyticAdminTable"
                height={350}
                loading={isLoading}
                idProperty="_id"
                columns={columns}
                rowData={list}
                gridOptions={{
                    reactiveCustomComponents: true,
                }}
                paginationPageSize={sizePerPage}
                totalSize={totalSize}
                onTableChanged={onTableChanged}
                emptyText={"No data!"}
                rowSelection="multiple"
                sizePerPageList={sizePerPageList}
                groupButton={() => (
                    <div className="pb-4 d-flex">
                        <Select
                            name="dateType"
                            options={ListDateType.filter((v) => v.value)}
                            className="al-select"
                            classNamePrefix="select"
                            value={ListDateType.filter(({ value }) => value === dateType)}
                            onChange={(e) => {
                                setDateType(e?.value || "");
                            }}
                            placeholder="Select a date ... "
                        />
                        {dateType === "custom" && filter?.dateStart && filter?.dateEnd && (
                            <div>
                                <DateRangePicker
                                    onCallback={handleEventDate}
                                    initialSettings={{
                                        startDate: formatterCreateAt(filter.dateStart, "dmy"),
                                        endDate: formatterCreateAt(filter.dateEnd, "dmy"),
                                        showDropdowns: true,
                                        locale: { format: "DD/MM/YYYY" },
                                    }}
                                >
                                    <input
                                        type="text"
                                        className="form-control al-form-control-daterangepicker mr-1 h-100 ms-4"
                                    />
                                </DateRangePicker>
                            </div>
                        )}
                    </div>
                )}
                isSearchText={false}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { getInfoTotal };

export default connect(mapStateToProps, mapDispatchToProps)(AdminTable);
