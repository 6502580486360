import { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { getProductEsty, getProductShopEsty, uploadTemplateByLink } from "actions/spytoolActions";
import { getProductTemplate } from "actions/productTemplateAction";
import { sleep, countString } from "components/Function";
import _ from "lodash";
import { CSVLink } from "react-csv";
import ModalImport from "./modalImport";
import ModalChangeText from "./modalChangeText";
import Swal from "sweetalert2";



function EstyProduct(props) {
    const { t } = useTranslation();
    const [shop, setShop] = useState("");
    const [listLink, setListLink] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [dataShop, setDataShop] = useState([]);
    const [dataLink, setDataLink] = useState([]);
    const [selected, setSelected] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [dataExport, setDataExport] = useState([]);
    const [minSale, setMinSale] = useState(-1);
    const [searchText, setSearchText] = useState("");
    const [totalProduct, setTotalProduct] = useState([]);
    const btnRef = useRef(null);
    const [initiateDownload, setInitiateDownload] = useState(false);

    const { getProductEsty, getProductShopEsty, getProductTemplate, uploadTemplateByLink, countString } = props;
    const { list } = props.productTemplate
    const list_template = list.map((v, i) => {
        return { "value": v._id, "label": v.title }
    })

    const onChange = (e) => {
        if (e.target.name === "shop") {
            setShop(e.target.value)
        }
        if (e.target.name === "listLink") {
            setListLink(e.target.value)
        }
        if (e.target.name === "minSale") {
            setMinSale(e.target.value)
        }
        if (e.target.name === "searchText") {
            setSearchText(e.target.value)
        }
        if (e.target.name === "select_link") {
            if (e.target.checked) {
                setSelected((prev) => {
                    const dataRs = [...prev, e.target.value];
                    return dataRs;
                });
            } else {
                setSelected((prev) => {
                    const dataRs = prev?.filter(item => item !== e.target.value);
                    return dataRs;
                });
            }
        }
        if (e.target.name.includes('title_')) {
            var id_change = parseInt(e.target.name?.split('title_')?.[1]);
            var value_new = e.target.value;
            const new_title = dataLink?.map((v, i) => {
                if (v.id === id_change) {
                    v.title = value_new;
                }
                return v;
            })
            setDataLink(new_title);
        }

    }
    const selectedAll = () => {
        setIsSelected(!isSelected)
    }
    const onClickExportData = async () => {
        setIsLoading(true)
        let save_data_export = [];
        if (isSelected) {
            save_data_export = dataLink?.filter((v, i) => v?.title?.toLowerCase()?.includes(searchText?.toLowerCase()) && v?.sales >= minSale)?.map((v, i) => {
                return {
                    ...v, image1: v.primaryImage?.split(v.primaryImage?.match(/il_(.*?)\./g)?.[0]).join('il_fullxfull.'), image2: "", image3: "", image4: "", image5: "", image6: "", image7: "", image8: "", image9: ""
                }
            })
        } else {
            save_data_export = dataLink.filter((v, i) => selected.includes(v.id.toString()))?.map((v, i) => {
                return {
                    ...v, image1: v.primaryImage?.split(v.primaryImage?.match(/il_(.*?)\./g)?.[0]).join('il_fullxfull.'), image2: "", image3: "", image4: "", image5: "", image6: "", image7: "", image8: "", image9: ""
                }
            })
        }
        setDataExport(save_data_export)
        setInitiateDownload(true);
        await sleep(2500)
        setIsLoading(false)
    }

    useEffect(() => {
        getProductTemplate()
    }, [getProductTemplate]);

    useEffect(() => {
        setInitiateDownload(false);
        if (initiateDownload) {
            btnRef.current?.click();
        }
    }, [initiateDownload]);

    const onClickCrawl = async (type) => {
        setIsLoading(true)
        if (type === "link") {
            setDataShop([])
            setDataLink([])
            setMinSale(0)
            var uniqLink = _.uniq(listLink?.trim().split('\n')?.filter((v, i) => v !== "" && v.includes("etsy."))?.map((v, i) => v?.split('/listing/')[1]?.split('/')[0]));
            var chunkLink = _.chunk(uniqLink, 25);
            setTotalProduct(chunkLink?.join(',')?.split(',')?.length);
            Promise.all(chunkLink?.map(async (list_id, iv) => {
                await sleep(100 * iv)
                return new Promise(async (resolve2, reject2) => {
                    await getProductEsty(list_id, iv)
                        .then((data) => {
                            data?.data?.listing_info?.map((v, i) => {
                                setDataLink((prev) => {
                                    var title = v?.title?.replace(/&#39;/g, "");
                                    var dataRs = [...prev, { ...v, title }];
                                    return dataRs;
                                });
                                return v;
                            });
                            resolve2('ok');
                        })
                        .catch((err) => {
                            resolve2('ok');
                        });

                });
            })).then(async (dat) => {
                setIsLoading(false)
                return Swal.fire(t("Success"), t("Crawl Success!"), "success");
            })
        } else {
            setDataShop([])
            setDataLink([])
            setMinSale(0)
            await getProductShopEsty(shop)
                .then(async (data) => {
                    var listing_shop = data.data.listing_shop;
                    // setDataLink(listing_shop);
                    // setIsLoading(false)
                    // return Swal.fire(t("Success"), t("Crawl Success!"), "success");
                    // setTotalProduct(listing_shop?.join(',')?.split(',')?.length);
                    // setIsLoading(false)
                    // await Promise.all(listing_shop?.map(async (list_id, iv) => {
                    //     await sleep(1000 * iv)
                    //     return new Promise(async (resolve2, reject2) => {
                    //         await getProductEsty(list_id, iv)
                    //             .then((data) => {
                    //                 data?.data?.listing_info?.map((v, i) => {
                    //                     setDataLink((prev) => {
                    //                         var title = v?.title?.replace(/&#39;/g, "");
                    //                         var dataRs = [...prev, { ...v, title }];
                    //                         return dataRs;
                    //                     });
                    //                     return v;
                    //                 });
                    //             })
                    //             .catch((err) => {
                    //                 resolve2('ok');
                    //             });

                    //     });
                    // })).then(async (b) => {
                    // })
                    setTotalProduct(listing_shop?.length);
                    setIsLoading(false)
                    setDataLink(listing_shop)
                    // await Promise.all(listing_shop?.map(async (list_id, iv) => {
                    //     await sleep(1000 * iv)
                    //     return new Promise(async (resolve2, reject2) => {
                    //         await getProductEsty(list_id, iv)
                    //             .then((data) => {
                    //                 data?.data?.listing_info?.map((v, i) => {
                    //                     setDataLink((prev) => {
                    //                         var title = v?.title?.replace(/&#39;/g, "");
                    //                         var dataRs = [...prev, { ...v, title }];
                    //                         return dataRs;
                    //                     });
                    //                     return v;
                    //                 });
                    //             })
                    //             .catch((err) => {
                    //                 resolve2('ok');
                    //             });

                    //     });
                    // })).then(async (b) => {
                    // })
                })
                .catch((err) => {
                    setIsLoading(false)
                    return Swal.fire(t("Success"), t("Crawl Success!"), "success");

                });
        }
    };

    const handleChangeTitle = async (data) => {
        var new_change_title = dataLink?.map((v, i) => {
            let textStart = data?.textStart ? data?.textStart + " " : "";
            let textEnd = data?.textEnd ? " " + data?.textEnd : "";
            let change_text = textStart + v?.title?.toLowerCase().split(data?.textOld?.toLowerCase() || "").join((data?.textNew || "")?.toLowerCase()) + textEnd;
            let new_title = change_text?.split(' ')?.map((v, i) => v?.[0]?.toUpperCase() + v?.slice(1))?.join(' ');
            v.title = new_title;
            return v;
        })
        new_change_title = _.uniqBy(new_change_title, "id");
        setDataLink([])
        setDataLink(new_change_title);
        return Swal.fire(t("Success"), t("Change Success!"), "success");
    };

    const onDelete = async (id) => {
        setDataLink(dataLink?.filter((v, i) => v?.id !== id))
    };

    const handleSubmitUpload = async (data) => {
        setIsLoading(true)
        let total_success = 0;
        let data_uniqe = _.uniqBy(data, "id");

        for (let i = 0; i < data_uniqe?.length; i++) {
            let obj = data_uniqe[i];
            // eslint-disable-next-line no-loop-func
            await uploadTemplateByLink(obj).then(() => total_success++)
                .catch((err) => {

                });
        }
        setIsLoading(false)
        setDataLink(dataLink?.filter((v, i) => !data_uniqe?.map((o, k) => o.id).includes(v.id)))
        setSelected([])
        return Swal.fire(t("Success"), t("Upload Success " + total_success + "/" + data_uniqe?.length + " !"), "success");
    };

    const deleteRowSelect = async () => {
        setIsLoading(true)
        if (isSelected) {
            setDataLink(dataLink?.filter((v, i) => !v?.title?.toLowerCase()?.includes(searchText?.toLowerCase()) && v?.sales >= minSale));
        } else {
            setDataLink(dataLink?.filter((v, i) => !selected?.includes(v?.id?.toString()) && !v?.title?.toLowerCase()?.includes(searchText?.toLowerCase()) && v?.sales >= minSale));
        }
        setSearchText("")
        setSelected([])
        setIsLoading(false)
    };

    const sortData = async (data) => {
        setIsLoading(true)
        if (data === "ASC") {
            setDataLink(_.sortBy(dataLink, 'sales'))
        } else {
            setDataLink(_.sortBy(dataLink, 'sales').reverse())
        }
        setIsLoading(false)
    };

    return (
        <Fragment>
            <Loading isLoading={isLoading} />

            <div className="card mb-5 mb-xl-10">
                <h1 className="mb-5 mt-5 text-dark text-center">{t("Crawl Etsy")}</h1>
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="card-body">
                            <h4 className="mb-5 mt-5 text-dark text-center">{t("Link Shop Etsy")}</h4>
                            <div className="form-group mb-5">
                                <label>
                                    Only 1 Shop
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t("etsy.com/shop/....")}
                                    name="shop"
                                    onChange={(e) => { onChange(e) }}
                                />
                            </div>
                            <button
                                onClick={() => onClickCrawl("shop")}
                                type="submit"
                                id="kt_modal_users_search_submit"
                                className="btn btn-primary"
                            >
                                {t("Crawl Shop")}
                            </button>
                        </div>

                    </div>
                    <div className="col-lg-6 col-md-12">
                        <h4 className="mb-5 mt-5 text-dark text-center">{t("Link Listing Etsy")}</h4>
                        <div className="card-body">
                            <div className="form-group mb-5">
                                <label>1 link per line</label>
                                <div>
                                    <textarea
                                        className="form-control"
                                        type="text"
                                        name="listLink"
                                        placeholder=""
                                        rows={5}
                                        onChange={(e) => { onChange(e) }}
                                    />
                                </div>
                            </div>
                            <button
                                onClick={() => onClickCrawl("link")}
                                type="submit"
                                id="kt_modal_users_search_submit"
                                className="btn btn-primary"
                            >
                                {t("Crawl Link")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="card card-body col-lg-4 mb-5">
                    <h1 className="mb-5 mt-5 text-dark text-center">{t(`DATA CRAWL ${dataLink?.length > 0 && dataLink?.length + "/ " + totalProduct + " PRODUCTS"} `)}</h1>
                    <div className="d-flex">
                        <button onClick={() => { selectedAll() }} type="button" className={"btn " + (isSelected ? "btn-success" : "btn-secondary") + " btn-sm me-1"}>
                            <i className="fa fa-cloud-upload mr-1"></i> {t("Select all")}
                        </button>
                        <ModalImport
                            data={isSelected ? dataLink?.filter((v, i) => v?.title?.toLowerCase()?.includes(searchText?.toLowerCase()) && v?.sales >= minSale)?.map((v, i) => v.id) : selected}
                            dataLink={dataLink?.filter((v, i) => v?.title?.toLowerCase()?.includes(searchText?.toLowerCase()) && v?.sales >= minSale)}
                            template={list_template}
                            onSubmit={(data) => {
                                handleSubmitUpload(data)
                            }}
                        />
                        <button onClick={onClickExportData} className="btn btn-sm btn-info mr-1"><i className="ki-outline ki-exit-down"></i>Export</button>
                        <CSVLink filename={"data_crawl_" + dataExport.length + "_products.csv"} data={dataExport}>
                            <span ref={btnRef} />
                        </CSVLink>
                        <button className="btn btn-sm btn-danger" onClick={() => { deleteRowSelect() }}>Delete Selected</button>
                    </div>
                    <div className="card-body">
                        {dataShop.length > 0 && <>

                        </>}
                        {dataLink?.length > 0 && <>
                            <div className="d-flex">
                                <span className="">Min Sales: <input type="number" className="btn btn-sm border" value={minSale} placeholder={t("1")} name="minSale" onChange={(e) => { onChange(e) }} /></span>
                                <span className="">Search: <input type="text" className="btn btn-sm border" value={searchText} placeholder={t("Search text...")} name="searchText" onChange={(e) => { onChange(e) }} /></span>
                                <button className="btn btn-sm btn-dark mr-5" onClick={() => { sortData("DESC") }}>Sort Sales DESC</button>
                                <button className="btn btn-sm btn-warning" onClick={() => { sortData("ASC") }}>Sort Sales ASC</button>
                                <ModalChangeText
                                    onSubmit={(data) => {
                                        handleChangeTitle(data)
                                    }}
                                />
                            </div>

                            <table className="table table-striped">
                                <thead>
                                    <tr className="card-title text-gray-800 fw-bold">
                                        <th scope="col">#</th>
                                        <th scope="col">Shop Name</th>
                                        <th scope="col">ID</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Image</th>
                                        <th scope="col">Views</th>
                                        <th scope="col">Sales</th>
                                        <th scope="col">All Sales</th>
                                        <th scope="col">Favourites</th>
                                        <th scope="col">Recommendations</th>
                                        <th scope="col">Created Before</th>
                                        <th scope="col">Tags</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataLink?.map((v, k) => <><tr key={k} hidden={v?.sales >= minSale && v?.title?.toLowerCase()?.includes(searchText?.toLocaleLowerCase()) ? false : true}>
                                        <td>
                                            <div className="d-flex justify-content-evenly">
                                                <input type="checkbox" value={v.id} onChange={(e) => { onChange(e) }} name="select_link" disabled={isSelected} style={{ width: "40px", height: "40px" }} />
                                                <button type="button" className="btn btn-sm btn-danger" onClick={() => { onDelete(v.id) }}><i className="ki-outline ki-tablet-delete fs-2" ></i></button>
                                            </div>
                                        </td>
                                        <td><Link target="_blank" to={v.shopUrl}>{v.shopName}</Link></td>
                                        <td><Link target="_blank" to={v.url}>{v.id}</Link></td>
                                        <td>
                                            <div className={v?.title?.length < 140 && v?.title?.length > 40 ? "text-success" : "text-danger"}>Total: {v?.title?.length} <span className="text-info">{countString(v?.title)}</span></div>
                                            <textarea type="text" rows={10} cols={30} name={"title_" + v.id} value={v.title} onChange={(e) => { onChange(e) }} >{v.title}</textarea>
                                        </td>
                                        <td style={{ maxWidth: "500px" }}><div className={"d-flex border"}>
                                            <div className="">
                                                <img className="img-responsive" alt={v.title} src={v.primaryImage} width={300} />
                                            </div>
                                            <div className="">
                                                {v?.image_others && <div className="d-flex align-items-center flex-wrap">
                                                    {v?.image_others?.map((o, j) => <div className="">
                                                        <Link target="_blank" to={v.cover}><img className="img-responsive pd-5 border" alt={v.name} src={o} width={100} /></Link>
                                                    </div>)}
                                                </div>}
                                            </div>

                                            {/* <div className="col-lg-3 col-md-6">
                                                    <Link to={v.cover}><img className="img-responsive pd-5 border" alt={v.name} src={v.image2} width={300} /></Link>
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <Link to={v.cover}><img className="img-responsive pd-5 border" alt={v.name} src={v.image3} width={300} /></Link>
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <Link to={v.cover}><img className="img-responsive pd-5 border" alt={v.name} src={v.image4} width={300} /></Link>
                                                </div> */}
                                        </div></td>
                                        <td>{v?.views}</td>
                                        <td>{v?.sales}</td>
                                        <td>{v?.grossSales}</td>
                                        <td>{v?.favourites}</td>
                                        <td>{v?.recommendations}</td>
                                        <td>{v?.age} days</td>
                                        <td style={{ maxWidth: "300px" }}>{v?.tags?.map((o, i) => <><Link to="#" className="btn btn-sm me-1"><span className="badge badge-secondary badge-sm">{o}</span></Link></>)}</td>
                                    </tr></>
                                    )}
                                </tbody>
                            </table>

                        </>}
                    </div>
                </div>

            </div>
        </Fragment>
    );
}

EstyProduct.propTypes = {
    getProductEsty: PropTypes.func.isRequired,
    getProductShopEsty: PropTypes.func.isRequired,
    getProductTemplate: PropTypes.func.isRequired,
    uploadTemplateByLink: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    productTemplate: state.productTemplate
});
const mapDispatchToProps = {
    getProductEsty,
    getProductShopEsty,
    getProductTemplate,
    uploadTemplateByLink,
    countString,
};

export default connect(mapStateToProps, mapDispatchToProps)(EstyProduct);
