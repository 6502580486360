import axios from "axios";
import Swal from "sweetalert2";
import { tokenConfig } from "./websiteActions";
import { findKey } from "components/Function";
import { t } from "i18next";

const changeDescriptionsByAI = (title, images, setNewDescriptions) => (dispatch, getState) => {
	var body = {
		data: title
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to change by AI?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_AI', payload: true });
			axios.post('/api/ai/change', body, tokenConfig(getState)).then(res => {
				dispatch({ type: 'LOADING_AI', payload: false });
				if (res.data.status === 200) {
					var first_image = "";
					if (images.length > 0) {
						first_image = `<img src="${images?.[0]}" alt="" />`;
					}
					var random_image = "";
					if (images.length > 1) {
						random_image = `<img src="${images?.[Math.floor(Math.random() * (images.length - 1)) + 1]}" alt="" />`;
					}
					setNewDescriptions(first_image + res.data.data.data + random_image);
					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({ type: 'LOADING_AI', payload: false });
				Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

const changeTitleByAI = (data, setList) => (dispatch, getState) => {
	var body = {
		data
	};
	Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to change title by AI?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			dispatch({ type: 'LOADING_AI', payload: true });
			axios.post('/api/ai/change-title', body, tokenConfig(getState)).then(res => {
				dispatch({ type: 'LOADING_AI', payload: false });
				if (res.data.status === 200) {
					setList((prev) => {
						prev = prev.map((item) => {
							var title = item?.title;
							var title_find = findKey(res.data.data.data, item?.product_id.toString());
							if (title_find) {
								title = title_find;
							}
							return { ...item, title };
						});
						return prev;
					});

					Swal.fire(t("Success"), t(res.data.message), 'success');
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
				}
			}).catch(err => {
				dispatch({ type: 'LOADING_AI', payload: false });
				Swal.fire(t("Error"), t("An error occurred!"), 'error');
			});
		}
	})
};

const detectByAI = (image, setLoading) => (dispatch, getState) => {
	var body = {
		image
	};
	return Swal.fire({
		title: t("Are you sure?"),
		text: t("Are you sure to change title by AI?"),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: t("Sure"),
		cancelButtonText: t("Cancel")
	}).then((result) => {
		if (result.value) {
			// dispatch({ type: 'LOADING_AI', payload: true });
			setLoading(true);
			return axios.post('/api/ai/detect-title-description', body, tokenConfig(getState)).then(res => {
				// dispatch({ type: 'LOADING_AI', payload: false });
				setLoading(false);
				if (res.data.status === 200) {
					Swal.fire(t("Success"), t(res.data.message), 'success');
					return res.data.data.data;
				} else {
					Swal.fire(t("Error"), t(res.data.message), 'error');
					return res.data.message
				}
			}).catch(err => {
				// dispatch({ type: 'LOADING_AI', payload: false });
				setLoading(false);
				Swal.fire(t("Error"), t("An error occurred!"), 'error');
				return "An error occurred!"
			});
		}
	})
};

export {
	changeDescriptionsByAI,
	changeTitleByAI,
	detectByAI,
};
