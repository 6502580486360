import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AuthorizeRedirect = () => {
    let { search } = useLocation();
    useEffect(() => {
        if (window.location.href.includes("bhtik.com")) {
            window.location.href = `https://bhtik.com/admin/shops/authorize/redirect${search}`;
            return;
        }
        window.location.href = `https://cotik.app/admin/shops/authorize/redirect${search}`;
    }, [search]);

    return <></>;
};

export default AuthorizeRedirect;
