const productTotal = ({ data }) => {
    return (
        <div className="card card-body">
            <div className="row">
                <h3>Analytic Products</h3>
                <div className="col-md-4">
                    <div className="card text-white bg-warning mb-3">
                        <div className="p-4">Total Import</div>
                        <div className="card-body px-4 text-white">
                            <h5 className="card-title text-white">{data?.totalProductImport || 0}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card text-white bg-primary mb-3">
                        <div className="p-4">Total Listing</div>
                        <div className="card-body px-4 text-white">
                            <h5 className="card-title text-white">{data?.totalProductListing || 0}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card text-white bg-success mb-3">
                        <div className="p-4">Total Active</div>
                        <div className="card-body px-4 text-white">
                            <h5 className="card-title text-white">{data?.totalProductActive || 0}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default productTotal;
