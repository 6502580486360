import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactTableDataGrid } from "components/Table";
import {
    getPromotionAutomate,
    updatePromotionAutomate,
    changePromotionAutomate,
} from "actions/promotionAutomateActions";
import { getStaffs } from "actions/teamActions";
import { FormatterStatus, formatterCreateAt } from "components/Function";
import Loading from "components/Loading";
import SelectBox from "components/elements/selectBox";
import CustomHeader from "components/elements/customHeaderTable";
import ModalUpload from "./modalUpload";
import ModalChange from "./modalChange";

const Shop = ({
    getPromotionAutomate,
    promotionAutomate,
    getStaffs,
    updatePromotionAutomate,
    changePromotionAutomate,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [shopsSelect, setShopsSelect] = useState([]);
    const [filter, setFilter] = useState({});
    const [staffs, setStaffs] = useState([]);

    const { t } = useTranslation();
    const { list, totalsize, isLoading } = promotionAutomate;

    const columns = [
        {
            field: 'checkbox',
            cellClass: "centered-cell",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 50,
            sortable: false,
        },
        {
            field: "_id",
            width: 120,
            cellClass: "centered-cell",
            headerName: "",
            cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
            sortable: true,
            comparator: () => 0,
        },
        {
            field: "name",
            headerName: t("Name"),
            width: 220,
            sortable: false,
            flex: 1,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => (
                <div className="my-2">
                    <div className="d-flex lh-lg">{cell} - {row.code}</div>
                </div>
            ),
        },
        {
            field: "region",
            headerName: t("Region"),
            sortable: false,
        },
        {
            field: "note",
            headerName: t("Note"),
            sortable: true,
        },
        {
            field: "member_note",
            headerName: t("Member Note"),
            sortable: false,
        },
        {
            field: "memberData",
            headerName: t("Members"),
            sortable: false,
            width: 350,
            flex: 1,
            cellRenderer: ({ value: cell }) =>
                cell?.map((v, i) => (
                    <p>
                        - {v?.name} ({v?.email})
                    </p>
                )),
        },
        {
            field: "promotionautomates",
            headerName: t("Setting"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => (
                <div className="my-2">
                    <div className="d-flex lh-lg">FLS: {cell?.activity_price_amount || 0}% / {cell?.activity_price_amount_sold || 0}% ({cell?.time || 3} days) - Min: {cell?.minOrderFls} orders</div>
                </div>
            ),
        },
        {
            field: "promotionautomates",
            headerName: t("Automate"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => (
                <div className="my-2">
                    <div className="d-flex lh-lg">{cell?.automate}</div>
                </div>
            ),
        },
    ];

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    const onSelectionChanged = (data) => {
        if (data.api) {
            const ids = data.api.getSelectedRows().map((d) => d._id);
            setShopsSelect(ids);
        }
    };

    const handleChangePrice = async (data) => {
        await updatePromotionAutomate({ _id: shopsSelect, ...data });
    };

    const handleChange = async (data) => {
        await changePromotionAutomate({ _id: shopsSelect, data });
    };

    useEffect(() => {
        getPromotionAutomate({ page, sizeperpage: sizePerPage, search: searchText, filter });
    }, [getPromotionAutomate, page, sizePerPage, searchText, filter]);

    useEffect(() => {
        getStaffs({ sizeperpage: 100 }).then((data) => {
            setStaffs(data?.data?.map((staff) => ({ value: staff?.user?._id, label: staff.user.email })) || []);
        });
    }, [getStaffs]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);
    const groupButton = () => {
        return (
            <div className="mb-5 d-flex gap-2 flex-wrap pb-4">
                <SelectBox
                    options={staffs}
                    name="filter_staff"
                    value={filter.staff || ""}
                    onChange={(data) => {
                        setFilter((prev) => ({
                            ...prev,
                            staff: data.value || "",
                        }));
                        setPage(1);
                    }}
                />
                <ModalUpload onSubmit={handleChangePrice} />
                <ModalChange onSubmit={handleChange} />

            </div>
        );
    };

    const onTableChanged = ({ page, limit, text }) => {
        if (limit) setSizePerPage(limit);
        if (page) setPage(page);
        if (typeof text === "string") setSearchText(text);
    };

    const defaultColDef = useMemo(() => ({
        wrapText: true,
        autoHeight: true,
        autoHeaderHeight: true,
    }), []);


    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onSortChanged = (e) => {
        if (e.source === "uiColumnSorted") {
            console.log(e.api.setSuppressAnimations);
            const sortedColumns = e.api.getColumnState().filter((col) => col.sort);
            console.log(sortedColumns?.[0]?.colId, sortedColumns?.[0]?.sort);
        }
    };

    return (
        <div className="card mb-5 mb-xl-10">
            <Loading isLoading={isLoading} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    {/* <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                        isSelectRow={true}
                        selectRow={selectRow}
                    /> */}
                    <ReactTableDataGrid
                        tableName="shopConnect"
                        idProperty="_id"
                        columns={columns}
                        rowData={list}
                        defaultColDef={defaultColDef}
                        gridOptions={{
                            reactiveCustomComponents: true,
                        }}
                        paginationPageSize={sizePerPage}
                        totalSize={totalSize}
                        onTableChanged={onTableChanged}
                        emptyText={t("No data!")}
                        rowSelection="multiple"
                        onSelectionChanged={onSelectionChanged}
                        sizePerPageList={sizePerPageList}
                        groupButton={groupButton}
                        onSortChanged={onSortChanged}
                        components={components}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ promotionAutomate }) => ({ promotionAutomate });
const mapDispatchToProps = {
    getPromotionAutomate,
    getStaffs,
    FormatterStatus,
    formatterCreateAt,
    updatePromotionAutomate,
    changePromotionAutomate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
