import { Fragment } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { Route, Routes } from "react-router-dom";
import "./assets/css/an.css";
import "./assets/css/style.bundle.css";
import "./assets/plugins/global/plugins.bundle.css";
import Admin from "./layout/admin/Main";
import Shop from "./layout/shop";
import ShopMessage from "./layout/shopMessage";
import ShopPromotion from "./layout/shopPromotions";
import ShopAuthorize from "./layout/shop/authorize";
import ShopMessageAuthorize from "./layout/shopMessage/authorzire";
import ShopPromotionAuthorize from "./layout/shopPromotions/authorzire";
import Home from "./layout/home";
import Notification from "./layout/notification";
import Promotions from "./layout/promotions/Main";
import PromotionsLogs from "./layout/promotionslogs/Main";
import Products from "./layout/products/Main";
import EditProduct from "./layout/products/edit";
import ProductList from "./layout/products/listproducts";
import EditProductTemplate from "./layout/productTemplate/edit";
import ProductTemplateList from "./layout/productTemplate/listproducts";
import AccountSetting from "./layout/account/Main";
import AccountPackage from "./layout/accountPackage";
import ProductTools from "./layout/products/tools";
import Orders from "./layout/orders/Main";
import UserStaff from "./layout/userStaff";
import Finance from "./layout/finance/Main";
import FinanceOverview from "./layout/finance/overview";
import Statement from "./layout/finance/statements";
import PaymentTiktok from "./layout/finance/payments";
import Packages from "./layout/packages";
import ManageDesign from "./layout/design/managedesign";
import ProductTikTok from "./layout/products/productTiktok";
import SpyProductEsty from "./layout/spyproduct/esty";
import SpyProductTikTokv1 from "./layout/spyproduct/tiktokv1";
import SpyProductTikTokv2 from "./layout/spyproduct/tiktokv2/Main";
import SpyProductSuggests from "./layout/spyproduct/suggest";
import SpyAmazon from "./layout/spyproduct/amazon";
import CategoryProduct from "./layout/categoryProduct/ListCategoryProduct";
import ProductFreeze from "./layout/products/productFreeze";
import ReturnOrders from "./layout/returnsRefundCancel/return";
import CancelOrders from "./layout/returnsRefundCancel/cancel";
import ProductSchedule from "./layout/products/productSchedule";
import ProductTikTokSpy from "./layout/spyproduct/tiktokProduct";
import PromotionAutomate from "./layout/promotionAutomate";
import { permissionComponent } from "./Function";

import ExtensionSettings from "./layout/extension/settings/Main";

import ExtensionDropTaobao from "./layout/extension/drop/taobao/Main";
import ExtensionDropAmazon from "./layout/extension/drop/amazon/Main";
import ExtensionDropTiktok from "./layout/extension/drop/tiktok/Main";
import ExtensionDropWalmart from "./layout/extension/drop/walmart/Main";
import ExtensionDropAliexpress from "./layout/extension/drop/aliexpress/Main";
import ExtensionDropTemu from "./layout/extension/drop/temu/Main";
import ExtensionDropCJ from "./layout/extension/drop/cj/Main";
import ExtensionDrop1688 from "./layout/extension/drop/1688/Main";
import ExtensionDropTHGFulfill from "./layout/extension/drop/thgfulfill/Main";
import ExtensionDropOther from "./layout/extension/drop/other/Main";
import ExtensionDropEbay from "./layout/extension/drop/ebay/Main";
import ExtensionDropAlibaba from "./layout/extension/drop/alibaba/Main";

import ExtensionPodEtsy from "./layout/extension/pod/etsy/Main";
import ExtensionPodAmazon from "./layout/extension/pod/amazon/Main";
import ExtensionPodEbay from "./layout/extension/pod/ebay/Main";
import ExtensionPodPinterest from "./layout/extension/pod/pinterest/Main";
import ExtensionPodGoogle from "./layout/extension/pod/google/Main";
import ExtensionPodTiktok from "./layout/extension/pod/tiktok/Main";
import ExtensionPodRedbubble from "./layout/extension/pod/redbubble/Main";
import ExtensionPodWalmart from "./layout/extension/pod/walmart/Main";
import ExtensionPodAliexpress from "./layout/extension/pod/aliexpress/Main";
import ExtensionPodZazzle from "./layout/extension/pod/zazzle/Main";
import ExtensionPodTemu from "./layout/extension/pod/temu/Main";
import ExtensionPodWoocommerce from "./layout/extension/pod/woocommerce/Main";
import ExtensionPodShopify from "./layout/extension/pod/shopify/Main";

import Messages from "./layout/messages/Main";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function Body(props) {
    var { pathname } = useLocation();

    return (
        <Fragment>
            <Helmet>
                <title>{pathname.indexOf("/extension") < 0 ? "CoTik" : "CoTik Extension!"}</title>
            </Helmet>
            <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl mw-unset">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/manager" element={<Admin />} />
                        <Route path="/shops" element={<Shop />} />
                        <Route path="/shops-message" element={<ShopMessage />} />
                        <Route path="/shops-promotion" element={<ShopPromotion />} />
                        <Route path="/messages" element={<Messages />} />
                        <Route path="/shops/authorize/:name" element={<ShopAuthorize />} />
                        <Route path="/shops-message/authorize/:name" element={<ShopMessageAuthorize />} />
                        <Route path="/shops-promotion/authorize/:name" element={<ShopPromotionAuthorize />} />
                        <Route path="/notification" element={<Notification />} />
                        <Route path="/promotions" element={<Promotions />} />
                        <Route path="/promotions-logs" element={<PromotionsLogs />} />
                        <Route path="/promotions-automate" element={<PromotionAutomate />} />
                        <Route
                            path="/products"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "GET_PRODUCT",
                                component: <ProductList />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/products/create"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <Products />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/products/tools"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <ProductTools />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/categories"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <CategoryProduct />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/product-freeze"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <ProductFreeze />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/product-tiktoks"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "UPDATE_PRODUCT",
                                component: <ProductTikTok />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/products/edit/:id"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "UPDATE_PRODUCT",
                                component: <EditProduct />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/product-template"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "GET_PRODUCT_TEMPLATE",
                                component: <ProductTemplateList />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/product-template/edit/:id"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "UPDATE_PRODUCT_TEMPLATE",
                                component: <EditProductTemplate />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/product-schedule"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "GET_PRODUCT_TEMPLATE",
                                component: <ProductSchedule />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/spy/etsy"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <SpyProductEsty />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/spy/suggests"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <SpyProductSuggests />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/spy/tiktokv1"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <SpyProductTikTokv1 />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/spy/tiktokv2"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <SpyProductTikTokv2 />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/spy/tiktok"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <ProductTikTokSpy />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/spy/amazon"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "CREATE_PRODUCT",
                                component: <SpyAmazon />,
                                type: "component",
                            })}
                        />
                        <Route path="/accounts" element={<AccountSetting />} />
                        <Route path="/account-package" element={<AccountPackage />} />
                        <Route
                            path="/orders"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "GET_ORDER",
                                component: <Orders />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/users-staff"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <UserStaff />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/finances"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <Finance />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/finances/statements"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <Statement />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/finances/payments"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <PaymentTiktok />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/finances/overview"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <FinanceOverview />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/packages"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <Packages />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/design/manage"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: <ManageDesign />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/design/buy"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "USER_REAL",
                                component: "Please waiting update",
                                type: "component",
                            })}
                        />
                        <Route
                            path="/returns"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "GET_ORDER_RETURN",
                                component: <ReturnOrders />,
                                type: "component",
                            })}
                        />
                        <Route
                            path="/cancels"
                            element={permissionComponent({
                                user: props.user.user,
                                permission: "GET_ORDER_CANCEL",
                                component: <CancelOrders />,
                                type: "component",
                            })}
                        />

                        <Route path="/extension-settings" element={<ExtensionSettings />} />

                        <Route path="/extension-drop-taobao" element={<ExtensionDropTaobao />} />
                        <Route path="/extension-drop-amazon" element={<ExtensionDropAmazon />} />
                        <Route path="/extension-drop-tiktok" element={<ExtensionDropTiktok />} />
                        <Route path="/extension-drop-walmart" element={<ExtensionDropWalmart />} />
                        <Route path="/extension-drop-aliexpress" element={<ExtensionDropAliexpress />} />
                        <Route path="/extension-drop-temu" element={<ExtensionDropTemu />} />
                        <Route path="/extension-drop-cj" element={<ExtensionDropCJ />} />
                        <Route path="/extension-drop-1688" element={<ExtensionDrop1688 />} />
                        <Route path="/extension-drop-thgfulfill" element={<ExtensionDropTHGFulfill />} />
                        <Route path="/extension-drop-other" element={<ExtensionDropOther />} />
                        <Route path="/extension-drop-ebay" element={<ExtensionDropEbay />} />
                        <Route path="/extension-drop-alibaba" element={<ExtensionDropAlibaba />} />

                        <Route path="/extension-pod-etsy" element={<ExtensionPodEtsy />} />
                        <Route path="/extension-pod-amazon" element={<ExtensionPodAmazon />} />
                        <Route path="/extension-pod-ebay" element={<ExtensionPodEbay />} />
                        <Route path="/extension-pod-pinterest" element={<ExtensionPodPinterest />} />
                        <Route path="/extension-pod-google" element={<ExtensionPodGoogle />} />
                        <Route path="/extension-pod-tiktok" element={<ExtensionPodTiktok />} />
                        <Route path="/extension-pod-redbubble" element={<ExtensionPodRedbubble />} />
                        <Route path="/extension-pod-walmart" element={<ExtensionPodWalmart />} />
                        <Route path="/extension-pod-aliexpress" element={<ExtensionPodAliexpress />} />
                        <Route path="/extension-pod-zazzle" element={<ExtensionPodZazzle />} />
                        <Route path="/extension-pod-temu" element={<ExtensionPodTemu />} />
                        <Route path="/extension-pod-woocommerce" element={<ExtensionPodWoocommerce />} />
                        <Route path="/extension-pod-shopify" element={<ExtensionPodShopify />} />
                    </Routes>
                </div>
            </div>
        </Fragment>
    );
}

// Body.propTypes = {
// };
const mapStateToProps = (state) => ({
    user: state.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
