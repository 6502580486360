import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactTable, ReactTableDataGrid } from "components/Table";
import { getShops, deleteShop, refreshToken } from "actions/shopPromotionActions";
import AddShop from "./addShop";
import { getStaffs } from "actions/teamActions";
import { FormatterStatus, formatterCreateAt } from "components/Function";
import Loading from "components/Loading";
import CustomHeader from "components/elements/customHeaderTable";

const Shop = ({
    getShops,
    shop,
    FormatterStatus,
    deleteShop,
    formatterCreateAt,
    refreshToken,
}) => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [searchText, setSearchText] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const [shopsSelect, setShopsSelect] = useState([]);
    const [filter, setFilter] = useState({});
    const [sort_by, setSortBy] = useState("");
    const [sort_order, setSortOrder] = useState("");

    const { t } = useTranslation();
    const { list, totalsize, isLoading } = shop;

    const columns = [
        {
            field: "_id",
            headerName: "",
            width: 120,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => rowIndex + 1,
            sortable: true,
            comparator: () => 0,
        },
        {
            field: "name",
            headerName: t("Name"),
            sortable: false,
            flex: 1,
        },
        {
            field: "region",
            headerName: t("Region"),
            sortable: false,
        },
        {
            field: "status",
            headerName: t("Status"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) => <FormatterStatus status={cell || "Connected"} />,
        },
        {
            field: "code",
            headerName: t("Code"),
            sortable: false,
        },
        {
            field: "-",
            headerName: t("Actions"),
            sortable: false,
            cellRenderer: ({ rowIndex, value: cell, data: row }) =>
                <> <button
                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Delete"
                    onClick={() => handleDelete(row._id)}
                >
                    <i className="ki-outline ki-trash fs-2"></i>
                </button></>
        },
    ];

    const handleDelete = (id) => {
        deleteShop({ id, page, sizePerPage, searchText, filter, sort_by, sort_order });
    };

    const sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "150",
            value: 150,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "All",
            value: totalsize || 50,
        },
    ];

    const onSelect = (row, isSelect) => {
        let shopsSelectTmp = shopsSelect;
        if (isSelect) {
            shopsSelectTmp.push(row._id);
        } else {
            shopsSelectTmp = shopsSelectTmp.filter((shopId) => shopId !== row._id);
        }
        const new_arr = JSON.stringify(shopsSelectTmp);
        setShopsSelect(JSON.parse(new_arr));
    };

    const onSelectAll = (isSelect, row) => {
        let shopsSelectTmp = shopsSelect;
        if (isSelect) {
            shopsSelectTmp = row.map((v, i) => v._id);
        } else {
            shopsSelectTmp = [];
        }
        setShopsSelect(shopsSelectTmp);
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        selected: shopsSelect,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
        selectionHeaderRenderer: ({ indeterminate, checked, onChange }) => (
            <div className="d-flex">
                <input
                    type="checkbox"
                    ref={(input) => {
                        if (input) {
                            input.indeterminate = indeterminate;
                            input.checked = checked;
                        }
                    }}
                    onChange={onChange}
                />
                <span style={{ marginLeft: '8px' }}>({shopsSelect.length})</span>
            </div>
        ),
    };

    useEffect(() => {
        getShops({ page, sizeperpage: sizePerPage, search: searchText, filter, sort_by, sort_order });
    }, [getShops, page, sizePerPage, searchText, filter, sort_by, sort_order]);

    useEffect(() => {
        setTotalSize(totalsize);
    }, [totalsize]);

    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
        if (type === "search") {
            setPage(1);
            setSizePerPage(50);
            setSearchText(searchText);
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
    };

    const handleRefreshToken = async (data) => {
        return refreshToken({ shops: shopsSelect, page, sizePerPage, searchText, filter, sort_by, sort_order })
    };

    const groupButton = () => {
        return (
            <div className="mb-5 d-flex gap-2">
                <AddShop />
                <button onClick={handleRefreshToken} className="btn btn-info">
                    Refresh Token
                </button>
            </div>
        );
    };

    const onTableChanged = ({ page, limit, text }) => {
        if (limit) setSizePerPage(limit);
        if (page) setPage(page);
        if (typeof text === "string") setSearchText(text);
    };

    const components = useMemo(() => {
        return {
            agColumnHeader: CustomHeader,
        };
    }, []);

    const onSortChanged = (e) => {
        if (e.source === "uiColumnSorted") {
            console.log(e.api.setSuppressAnimations);
            const sortedColumns = e.api.getColumnState().filter((col) => col.sort);
            console.log(sortedColumns?.[0]?.colId, sortedColumns?.[0]?.sort);

            setSortBy(sortedColumns?.[0]?.colId);
            setSortOrder(sortedColumns?.[0]?.sort || "desc");
        }
    };

    return (
        <div className="card mb-5 mb-xl-10">
            <Loading isLoading={isLoading} />
            <div className="card-body">
                <div className="al-variants-table al-max-height-table-cus">
                    {/* <ReactTable
                        columns={columns}
                        data={list || []}
                        groupButton={groupButton}
                        handleTableChange={handleTableChange}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        alClassName="table-layout-inherit"
                        sizePerPageList={sizePerPageList}
                        isSelectRow={true}
                        selectRow={selectRow}
                    /> */}
                    <ReactTableDataGrid
                        tableName="shopPromotionConnect"
                        idProperty="_id"
                        columns={columns}
                        rowData={list}
                        gridOptions={{
                            reactiveCustomComponents: true,
                        }}
                        paginationPageSize={sizePerPage}
                        totalSize={totalSize}
                        onTableChanged={onTableChanged}
                        emptyText={t("No data!")}
                        rowSelection="multiple"
                        sizePerPageList={sizePerPageList}
                        groupButton={groupButton}
                        onSortChanged={onSortChanged}
                        components={components}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ shopPromotion, user }) => ({ shop: shopPromotion, user });
const mapDispatchToProps = {
    getShops,
    getStaffs,
    FormatterStatus,
    formatterCreateAt,
    deleteShop,
    refreshToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
