import { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { addProductSchedule } from "actions/productScheduleActions";


const ModalSchedule = ({ data, shops, onClose, onSubmit, addProductSchedule }) => {
    const { t } = useTranslation();
    const [upload_at, setUploadAt] = useState(new Date());
    const [shop, setShop] = useState("");

    const onChangeShop = (e) => {
        var shops_tmp = e.map((v, i) => {
            return v.value
        });
        setShop(shops_tmp);
    }

    const handleSubmit = async () => {
        await addProductSchedule({ _id: data?._id, title: data?.title, main_images: data?.main_images?.[0], shop, upload_at, type: "system" })
        onSubmit?.();
        onClose?.();
    };

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={onClose} isOpen={!!data}>
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Schedule Upload")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onClose(); }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group mb-5">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className=""><b>{data?.title}</b></span>
                        </label>
                        <div>
                            <div className="symbol symbol-200px me-2">
                                <img src={data?.main_images?.[0]} alt="" className="thumbnail" />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-5">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Select Shop")}</span>
                        </label>
                        <div>
                            <Select
                                isClearable
                                isMulti
                                name="shop"
                                options={shops}
                                // className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table"
                                classNamePrefix="select"
                                value={shops.filter(({ value }) => shop.includes(value))}
                                onChange={(e) => { onChangeShop(e) }}
                                placeholder={t("Select a shop ... ")}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Upload At</label>
                        <div className="al-form-input-date">
                            <DatePicker
                                showTimeSelect
                                timeIntervals={1}
                                dateFormat="dd/MM/y HH:mm:ss"
                                timeFormat="HH:mm"
                                className="form-control w-100"
                                selected={upload_at}
                                onChange={setUploadAt}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={handleSubmit}  >{t("Save")}</button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({ productSchedule: state.productSchedule });

export default connect(mapStateToProps, { addProductSchedule })(ModalSchedule);
