import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalChange({ onSubmit }) {
    const [automate, setAutomate] = useState("NO");
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const onChange = (e) => {
        if (e.target.name === "automate") {
            setAutomate(e.target.value);
        }
    }

    const onClose = () => {
        setIsOpen(false);
    };

    const handleSubmit = () => {
        onSubmit(automate);
        onClose();
    };

    return (
        <Fragment>
            <button
                className="btn btn-sm btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
                onClick={() => setIsOpen(true)}
            >
                {t("Change Automate")}
            </button>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    onClose();
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Support for Flash Sale")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                onClose();
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group">
                        <label>Change to:</label>
                        <div className="al-form-input-date">
                            <select onChange={(e) => {
                                onChange(e);
                            }} name="automate" value={automate} className="form-control form-control-sm">
                                <option value="NO">NO</option>
                                <option value="APPROVE">APPROVE</option>
                            </select>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalChange);