import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { CSVLink } from "react-csv";
import Papa from "papaparse";

function ModalUploadCSV({ onSubmit }) {
    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [checkFile, setCheckFile] = useState("");
    const { t } = useTranslation();

    var data_template = [{
        title: "Title Example",
        image1: "https://cdn.cotik.app/6XCIdCq0r4gLXPidSKQtvcrKuQEyrNJpZ.jpeg",
        image2: "https://cdn.cotik.app/uJ1PYus6Crf1eE0rRTlt1pCB7jifmPaz.jpeg",
        image3: "https://cdn.cotik.app/uJ1PYus6Crf1eE0rRTlt1pCB7jifmPaz.jpeg",
        image4: "https://cdn.cotik.app/dXuINIkUvGRP2iw4c0sOeUfG5ihNuQ.jpeg",
        image5: "https://cdn.cotik.app/JC0Nds71NBei6XAS3MQUqSDEhyv5Xzmy9.jpeg",
        image6: "https://cdn.cotik.app/eONdCfq7PnDGb6ohJyquumOpCb5J7a.jpeg",
        image7: "",
        image8: "",
        image9: "",
        description: `<strong><em>WE PROVIDE FREE EDITING AND CUSTOMIZATION OF TEXT ON DESIGNS ACCORDING TO YOUR REQUIREMENTS. PLEASE MESSAGE US WITH YOUR REQUESTS.</em></strong>`,
        product_sku: "Product Sku Example",

    }];

    const onChange = (e) => {
        if (e.target.name === "file_to_import") {
            setCheckFile('')
            if (e?.target?.files?.[0]?.type === "text/csv") {
                var reader = new FileReader();
                reader.onload = async ({ target }) => {
                    const csv = Papa.parse(target.result, {
                        header: true,
                    });
                    const parsedData = csv?.data;
                    setData(parsedData);
                };
                reader.readAsText(e.target.files[0] || "");
            } else {
                setCheckFile('Only file CSV')
            }

        }
    };

    const handleSubmit = async () => {
        setCheckFile('')
        if (data?.length > 0) {
            await onSubmit(data);
            setIsOpen(false);
        } else {
            setCheckFile('Not found data. Please check!')
        }
    };

    return (
        <Fragment>
            <span onClick={() => setIsOpen(true)} className="btn btn-info btn-sm" role="button">
                <i className="ki-outline ki-file fs-2"></i> Upload Template CSV
            </span>
            <Modal
                centered={true}
                size="lg"
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
                isOpen={isOpen}
            >
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Upload Templates CSV")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i
                            className="ki-outline ki-cross fs-1"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="form-group mb-5">
                        <CSVLink filename={"import_tracking_template.csv"} data={data_template} className="btn btn-sm btn-dark mr-1"><i className="fa fa-file-excel-o mr-1"></i>Export File Example</CSVLink>
                    </div>
                    <div className="form-group">
                        <label className="text-danger">*Required fields: title, image1, image2</label>
                    </div>
                    <div className="form-group mb-5">
                        <label className="mb-2 h3">Select file to import</label>
                        <div className="custom-file mb-2">
                            <input className="custom-file-input" type="file" name="file_to_import" onChange={(e) => { onChange(e) }} />
                        </div>
                        <span className="text-danger">{checkFile}</span>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <button
                        type="reset"
                        id="kt_modal_users_search_reset"
                        data-bs-dismiss="modal"
                        className="btn btn-active-light me-3"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Cancel")}
                    </button>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        id="kt_modal_users_search_submit"
                        className="btn btn-primary"
                    >
                        {t("Save")}
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUploadCSV);
