const initialState = {
	isLoading: false,
	list: [],
	totalsize: 0,
};

function promotionAutomateReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_PROMOTION_AUTOMATE':
			return {
				...state,
				isLoading: action.payload
			};
		case 'LIST_PROMOTION_AUTOMATE':
			return {
				...state,
				list: action.payload.listpromotionautomates,
				totalsize: action.payload.totalsize
			};
		default:
			return state;
	}
}

export default promotionAutomateReducer;
