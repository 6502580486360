import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getDesigns } from "actions/designAction";
import { getProductTikTokMainImageByApiProductId } from "actions/productTikTokActions";
import { formatterImage } from "components/Function";
import { Link } from "react-router-dom";

import { saveEditOrder } from "actions/orderActions";
import Loading from "components/Loading";
import {
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Select from 'react-select';
import ReactImageMagnify from 'react-image-magnify';
import cx from "classnames";
import CreatableSelect from 'react-select/creatable';

function Modalproductsettings(props) {
    const [_id] = useState(props.productsettings._id);
    const [line_item_id] = useState(props.productsettings.line_item.id);
    const [product_id] = useState(props.productsettings.line_item.product_id);
    const [product_name] = useState(props.productsettings.line_item.product_name);
    const [main_images, setMainImages] = useState([]);
    const [design_fee, setDesignFee] = useState(props.productsettings.design_fee);
    const [mockup, setMockup] = useState(props.productsettings.mockup);
    const [design, setDesign] = useState(props.productsettings.design);
    const [design_match, setDesignMatch] = useState("");
    const [mockup_front, setMockupFront] = useState(props.productsettings.mockup?.[line_item_id]?.Front);
    const [mockup_back, setMockupBack] = useState(props.productsettings.mockup?.[line_item_id]?.Back);
    const [mockup_neck, setMockupNeck] = useState(props.productsettings.mockup?.[line_item_id]?.Neck);
    const [mockup_right, setMockupRight] = useState(props.productsettings.mockup?.[line_item_id]?.Right);
    const [mockup_left, setMockupLeft] = useState(props.productsettings.mockup?.[line_item_id]?.Left);
    const [mockup_aop_3d, setMockupAop3d] = useState(props.productsettings.mockup?.[line_item_id]?.AOP_3D);
    const [design_front, setDesignFront] = useState(props.productsettings.design?.[line_item_id]?.Front);
    const [design_back, setDesignBack] = useState(props.productsettings.design?.[line_item_id]?.Back);
    const [design_neck, setDesignNeck] = useState(props.productsettings.design?.[line_item_id]?.Neck);
    const [design_right, setDesignRight] = useState(props.productsettings.design?.[line_item_id]?.Right);
    const [design_left, setDesignLeft] = useState(props.productsettings.design?.[line_item_id]?.Left);
    const [design_aop_3d, setDesignAop3d] = useState(props.productsettings.design?.[line_item_id]?.AOP_3D);
    const [isEditMockupFront, setIsEditMockupFront] = useState(false);
    const [isEditMockupBack, setIsEditMockupBack] = useState(false);
    const [isEditMockupNeck, setIsEditMockupNeck] = useState(false);
    const [isEditMockupRight, setIsEditMockupRight] = useState(false);
    const [isEditMockupLeft, setIsEditMockupLeft] = useState(false);
    const [isEditMockupAop3d, setIsEditMockupAop3d] = useState(false);
    const [isEditDesignFront, setIsEditDesignFront] = useState(false);
    const [isEditDesignBack, setIsEditDesignBack] = useState(false);
    const [isEditDesignNeck, setIsEditDesignNeck] = useState(false);
    const [isEditDesignRight, setIsEditDesignRight] = useState(false);
    const [isEditDesignLeft, setIsEditDesignLeft] = useState(false);
    const [isEditDesignAop3d, setIsEditDesignAop3d] = useState(false);


    const [sku_name] = useState(props.productsettings.line_item.sku_name);
    const [order_status] = useState(props.productsettings.order_status);
    const [obj_printer, setObjPrinter] = useState(props.productsettings.printer);
    const [shipping_label, setShippingLabel] = useState(props.productsettings.shipping_label);
    const [printer, setPrinter] = useState(props.productsettings.printer?.[line_item_id]?.printer);
    const [manufacturer, setManufacturer] = useState(props.productsettings.printer?.[line_item_id]?.manufacturer);
    const [type, setType] = useState(props.productsettings.printer?.[line_item_id]?.type);
    const [printing_area, setPrintingArea] = useState(props.productsettings.printer?.[line_item_id]?.printing_area);
    const [shipping_method, setShippingMethod] = useState(props.productsettings.printer?.[line_item_id]?.shipping_method);
    const [color, setColor] = useState(props.productsettings.printer?.[line_item_id]?.color);
    const [size, setSize] = useState(props.productsettings.printer?.[line_item_id]?.size);
    const [base_cost, setBaseCost] = useState(props.productsettings.printer?.[line_item_id]?.base_cost);



    var { isLoading } = props.order;
    var { list } = props.design;
    var { isOpenModalProductSettings, openModal, getDesigns, saveEditOrder, getProductTikTokMainImageByApiProductId, formatterImage } = props;
    const { t } = useTranslation();
    const list_design = list.map((v, i) => {
        return { "label": `${v.title} (${v.keyword})`, "value": v._id, "mockup": v.mockup, "design": v.design };
    });
    const list_printer = [
        { "label": `Flashship`, "value": `flashship` },
        { "label": `Mangoteeprints`, "value": `mangoteeprints` },
        { "label": `Gearment`, "value": `gearment` },
        { "label": `HM Fulfill`, "value": `hmfulfill` },
        { "label": `Lenful`, "value": `lenful` },
        { "label": `OnosPOD`, "value": `onospod` },
        { "label": `A2K Ecom`, "value": `a2kecom` },
        { "label": `Printeeshub`, "value": `printeeshub` },
        { "label": `Monkeykingprint`, "value": `monkeykingprint` },
        { "label": `KV Fulfill`, "value": `kvfulfill` },
        // { "label": `Other`, "value": `other` },
    ];
    const list_manufacturer = [
        { "label": `Gildan`, "value": `gildan`, "enabled": ["flashship", "hmfulfill", "lenful", "printeeshub"] },
        { "label": `Bella canvas`, "value": `bellacanvas`, "enabled": ["flashship", "hmfulfill", "lenful"] },
        { "label": `Flash POD`, "value": `flashpod`, "enabled": ["flashship"] },
        { "label": `Comfort`, "value": `comfort`, "enabled": ["flashship", "hmfulfill", "lenful"] },
        { "label": `Tote bag`, "value": `totebag`, "enabled": ["flashship", "hmfulfill", "lenful"] },

        { "label": `Gildan Fast US`, "value": `gildanfastus`, "enabled": ["mangoteeprints"] },
        { "label": `Bella canvas Fast US`, "value": `bellacanvasfastus`, "enabled": ["mangoteeprints"] },
        { "label": `Comfort Fast US`, "value": `comfortfastus`, "enabled": ["mangoteeprints"] },
        { "label": `Tote bag Fast US`, "value": `totebagfastus`, "enabled": ["mangoteeprints"] },

        { "label": `Gildan Tiktok`, "value": `gildantiktok`, "enabled": ["mangoteeprints", "gearment", "a2kecom"] },
        { "label": `Bella canvas Tiktok`, "value": `bellacanvastiktok`, "enabled": ["mangoteeprints", "gearment", "a2kecom"] },
        { "label": `Comfort Tiktok`, "value": `comforttiktok`, "enabled": ["mangoteeprints", "gearment", "a2kecom"] },

        { "label": `Gildan US1`, "value": `gildanus1`, "enabled": ["mangoteeprints"] },
        { "label": `Bella canvas US1`, "value": `bellacanvasus1`, "enabled": ["mangoteeprints"] },
        { "label": `Comfort US1`, "value": `comfortus1`, "enabled": ["mangoteeprints"] },

        { "label": `All Over Print`, "value": `alloverprint`, "enabled": ["onospod"] },

        { "label": `Classic/Gildan`, "value": `classicgildan_monkey`, "enabled": ["monkeykingprint"] },
        { "label": `Comfort`, "value": `comfort_monkey`, "enabled": ["monkeykingprint"] },
        
        { "label": "iPhone", "value": "iphone", "enabled": ["kvfulfill"] },
        { "label": "Samsung", "value": "samsung", "enabled": ["kvfulfill"] },
        { "label": "Google Pixel", "value": "googlepixel", "enabled": ["kvfulfill"] },
        { "label": "Airpods", "value": "airpods", "enabled": ["kvfulfill"] },
        { "label": "Other", "value": "other", "enabled": ["kvfulfill"] },
    ];
    const list_type = [
        { "label": `T-Shirt G5000`, "value": `tshirt_g5000`, "enabled": ["gildan"] },
        { "label": `Sweatshirt G18000`, "value": `sweatshirt_g18000`, "enabled": ["gildan"] },
        { "label": `Hoodie G18500`, "value": `hoodie_g18500`, "enabled": ["gildan"] },
        { "label": `Tank top G2200`, "value": `tanktop_g2200`, "enabled": ["gildan"] },
        { "label": `T-Shirt B3001`, "value": `tshirt_b3001`, "enabled": ["bellacanvas"] },
        { "label": `T-Shirt FLPOD`, "value": `tshirt_flpod`, "enabled": ["flashpod"] },
        { "label": `T-Shirt C1717`, "value": `tshirt_c1717`, "enabled": ["comfort"] },
        { "label": `Tote bag`, "value": `totebag`, "enabled": ["totebag"] },

        { "label": `T-Shirt 5000`, "value": `tshirt_5000_fastus`, "enabled": ["gildanfastus"] },
        { "label": `Sweatshirt 18000`, "value": `sweatshirt_18000_fastus`, "enabled": ["gildanfastus"] },
        { "label": `Hoodie 18500`, "value": `hoodie_18500_fastus`, "enabled": ["gildanfastus"] },
        { "label": `Tank top 2200`, "value": `tanktop_2200`, "enabled": ["gildanfastus"] },

        { "label": `T-Shirt 5000`, "value": `tshirt_5000_tiktok`, "enabled": ["gildantiktok"] },
        { "label": `Sweatshirt 18000`, "value": `sweatshirt_18000_tiktok`, "enabled": ["gildantiktok"] },
        { "label": `Hoodie 18500`, "value": `hoodie_18500_tiktok`, "enabled": ["gildantiktok"] },

        { "label": `T-Shirt 5000`, "value": `tshirt_5000_us1`, "enabled": ["gildanus1"] },
        { "label": `Sweatshirt 18000`, "value": `sweatshirt_18000_us1`, "enabled": ["gildanus1"] },
        { "label": `Hoodie 18500`, "value": `hoodie_18500_us1`, "enabled": ["gildanus1"] },

        { "label": `T-Shirt 3001`, "value": `tshirt_3001`, "enabled": ["bellacanvasfastus", "bellacanvastiktok", "bellacanvasus1"] },
        { "label": `T-Shirt 1717`, "value": `tshirt_1717`, "enabled": ["comfortfastus", "comforttiktok", "comfortus1"] },
        { "label": `Tote bag`, "value": `totebag`, "enabled": ["totebagfastus"] },

        { "label": `Premium 3D T-Shirt`, "value": `premium3dtshirt`, "enabled": ["alloverprint"] },
        { "label": `Sweatshirt Unisex`, "value": `sweatshirtunisex`, "enabled": ["alloverprint"] },
        { "label": `Hoodie`, "value": `hoodie`, "enabled": ["alloverprint"] },
        { "label": `Tank Top Men`, "value": `tanktopmen`, "enabled": ["alloverprint"] },
        { "label": `Tank Top Women`, "value": `tanktopwomen`, "enabled": ["alloverprint"] },
        { "label": `Premium Polo Shirt Men`, "value": `premiumpoloshirtmen`, "enabled": ["alloverprint"] },
        { "label": `Premium Polo Shirt Women`, "value": `premiumpoloshirtwomen`, "enabled": ["alloverprint"] },

        { "label": `Classic T-Shirt`, "value": `Classic T-Shirt`, "enabled": ["classicgildan_monkey"] },
        { "label": `Sweatshirt`, "value": `Sweatshirt`, "enabled": ["classicgildan_monkey"] },
        { "label": `Hoodie`, "value": `Hoodie`, "enabled": ["classicgildan_monkey"] },
        { "label": `Comfort color T-shirt`, "value": `Comfort color T-shirt`, "enabled": ["comfort_monkey"] },

        { "label": "iPhone 11 Pro Max", "value": "TC11PRM", "enabled": ["iphone"] },
        { "label": "iPhone 12 Mini", "value": "TC12mini", "enabled": ["iphone"] },
        { "label": "iPhone 14 Pro Max", "value": "TC14PRM", "enabled": ["iphone"] },
        { "label": "iPhone 13 Pro Max", "value": "TC13PRM", "enabled": ["iphone"] },
        { "label": "iPhone 14 Pro", "value": "TC14PR", "enabled": ["iphone"] },
        { "label": "iPhone 14 Plus", "value": "TC14PL", "enabled": ["iphone"] },
        { "label": "iPhone 15 Pro", "value": "TC15PR", "enabled": ["iphone"] },
        { "label": "iPhone 15 Plus", "value": "TC15PL", "enabled": ["iphone"] },
        { "label": "iPhone 15", "value": "TC15", "enabled": ["iphone"] },
        { "label": "iPhone 12", "value": "TC12", "enabled": ["iphone"] },
        { "label": "iPhone 13 Mini", "value": "TC13mini", "enabled": ["iphone"] },
        { "label": "iPhone 13", "value": "TC13", "enabled": ["iphone"] },
        { "label": "iPhone 14", "value": "TC14", "enabled": ["iphone"] },
        { "label": "iPhone XS", "value": "TCXS", "enabled": ["iphone"] },
        { "label": "iPhone 15 Pro Max", "value": "TC15PRM", "enabled": ["iphone"] },
        { "label": "iPhone 13 Pro", "value": "TC13PR", "enabled": ["iphone"] },
        { "label": "Samsung Galaxy S22 Plus", "value": "TCSSGS22PL", "enabled": ["samsung"] },
        { "label": "Samsung Galaxy S21 Ultra", "value": "TCSSGS21UT", "enabled": ["samsung"] },
        { "label": "iPhone 12 Pro ", "value": "TC12PR", "enabled": ["iphone"] },
        { "label": "iPhone XR", "value": "TCXR", "enabled": ["iphone"] },
        { "label": "Google Pixel 7A", "value": "GGPX7A", "enabled": ["googlepixel"] },
        { "label": "Google Pixel 8", "value": "GGPX8", "enabled": ["googlepixel"] },
        { "label": "Google Pixel 8 Pro", "value": "GGPX8P", "enabled": ["googlepixel"] },
        { "label": "Airpods Pro ", "value": "APPRO", "enabled": ["airpods"] },
        { "label": "iPhone 7 Plus", "value": "TC7PL", "enabled": ["iphone"] },
        { "label": "Airpods Gen 1", "value": "AP1", "enabled": ["airpods"] },
        { "label": "Airpod Gen 2", "value": "AP2", "enabled": ["airpods"] },
        { "label": "Airpods Gen 3", "value": "AP3", "enabled": ["airpods"] },
        { "label": "Samsung Galaxy S22", "value": "TCSSGS22", "enabled": ["samsung"] },
        { "label": "Samsung Galaxy S23", "value": "TCSSGS23", "enabled": ["samsung"] },
        { "label": "Samsung Galaxy S21 Plus", "value": "TCSSGS21PL", "enabled": ["samsung"] },
        { "label": "Airpods Gen 4", "value": "AP4", "enabled": ["airpods"] },
        { "label": "Samsung Galaxy S22 Ultra", "value": "TCSSGS22UT", "enabled": ["samsung"] },
        { "label": "Samsung Galaxy S24 Ultra", "value": "TCSSGS24UT", "enabled": ["samsung"] },
        { "label": "Samsung Galaxy S21", "value": "TCSSGS21", "enabled": ["samsung"] },
        { "label": "Samsung Galaxy S23 Plus", "value": "TCSSGS23PL", "enabled": ["samsung"] },
        { "label": "Samsung Galaxy S23 Ultra", "value": "TCSSGS23UT", "enabled": ["samsung"] },
        { "label": "Airpods Pro 2", "value": "APPRO2", "enabled": ["airpods"] },
        { "label": "Samsung Galaxy S24 ", "value": "TCSSGS24", "enabled": ["samsung"] },
        { "label": "Samsung Galaxy S24 Plus", "value": "TCSSGS24PL", "enabled": ["samsung"] },
        { "label": "Shopee100g", "value": "Shopee100g", "enabled": ["other"] },
        { "label": "Shopee200g", "value": "Shopee200g", "enabled": ["other"] },
        { "label": "Shopee300g", "value": "Shopee300g", "enabled": ["other"] },
        { "label": "FulfillVNUS", "value": "FulfillVNUS", "enabled": ["other"] },
        { "label": "iPhone 16", "value": "TC16", "enabled": ["iphone"] },
        { "label": "iPhone 8", "value": "TC8", "enabled": ["iphone"] },
        { "label": "iPhone 11", "value": "TC11", "enabled": ["iphone"] },
        { "label": "iPhone 8 Plus", "value": "TC8PL", "enabled": ["iphone"] },
        { "label": "iPhone XS MAX", "value": "TCXSM", "enabled": ["iphone"] },
        { "label": "iPhone 16 Pro", "value": "TC16PR", "enabled": ["iphone"] },
        { "label": "iPhone X", "value": "TCX", "enabled": ["iphone"] },
        { "label": "iPhone 16 Pro Max", "value": "TC16PRM", "enabled": ["iphone"] },
        { "label": "iPhone 16 Plus", "value": "TC16PL", "enabled": ["iphone"] },
        { "label": "iPhone 11 Pro", "value": "TC11PR", "enabled": ["iphone"] },
        { "label": "iPhone SE", "value": "TCSE", "enabled": ["iphone"] },
        { "label": "iPhone 12 Pro Max", "value": "TC12PRM", "enabled": ["iphone"] },
        { "label": "iPhone 7", "value": "TC7", "enabled": ["iphone"] }
    ];
    const list_size = [
        { "label": `S`, "value": `s`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
        { "label": `M`, "value": `m`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
        { "label": `L`, "value": `l`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
        { "label": `XL`, "value": `xl`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
        { "label": `2XL`, "value": `2xl`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
        { "label": `3XL`, "value": `3xl`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
        { "label": `4XL`, "value": `4xl`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tshirt_b3001", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tshirt_3001", "tshirt_1717", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
        { "label": `5XL`, "value": `5xl`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tshirt_b3001", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tshirt_3001", "premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
    ];
    const list_color = [
        { "label": `Black`, "value": `black`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "totebag", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
        { "label": `White`, "value": `white`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_flpod", "tshirt_c1717", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "tshirt_3001", "tshirt_1717", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
        { "label": `Sportgrey`, "value": `sportgrey`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Sand`, "value": `sand`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Forestgreen`, "value": `forestgreen`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "Sweatshirt"] },
        { "label": `Lightpink`, "value": `lightpink`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Navy`, "value": `navy`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_fastus", "hoodie_18500_tiktok", "hoodie_18500_us1", "tanktop_2200", "sweatshirt_18000_tiktok", "tshirt_3001", "tshirt_1717", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
        { "label": `Militarygreen`, "value": `militarygreen`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Maroon`, "value": `maroon`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "tshirt_3001", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Red`, "value": `red`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "tshirt_3001", "tshirt_1717", "Classic T-Shirt", "Sweatshirt", "Hoodie", "Comfort color T-shirt"] },
        { "label": `Royalblue`, "value": `royalblue`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "sweatshirt_18000_fastus"] },
        { "label": `Royal`, "value": `royal`, "enabled": ["tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "sweatshirt_18000_tiktok", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Ashgrey`, "value": `ashgrey`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "sweatshirt_18000_fastus", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Ash`, "value": `ash`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_c1717", "totebag", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_tiktok", "sweatshirt_18000_us1", "hoodie_18500_tiktok", "tshirt_3001", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Lightblue`, "value": `lightblue`, "enabled": ["tshirt_g5000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "sweatshirt_18000_tiktok", "hoodie_18500_tiktok", "tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "sweatshirt_18000_tiktok", "tshirt_3001", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Charcoal`, "value": `charcoal`, "enabled": ["tshirt_g5000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_us1", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Darkheather`, "value": `darkheather`, "enabled": ["tshirt_g5000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "sweatshirt_18000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Darkgreyheather`, "value": `darkgreyheather`, "enabled": ["tshirt_b3001", "tshirt_3001"] },
        { "label": `Purple`, "value": `purple`, "enabled": ["tshirt_g5000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "sweatshirt_18000_tiktok", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Orange`, "value": `orange`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "tshirt_5000_fastus", "tshirt_5000_tiktok", "tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "sweatshirt_18000_fastus", "sweatshirt_18000_tiktok", "tshirt_3001", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Natural`, "value": `natural`, "enabled": ["tshirt_b3001", "totebag", "tshirt_3001", "tshirt_5000_tiktok", "Classic T-Shirt"] },
        { "label": `Pepper`, "value": `pepper`, "enabled": ["tshirt_c1717", "tshirt_1717", "Comfort color T-shirt"] },
        { "label": `Ivory`, "value": `ivory`, "enabled": ["tshirt_c1717", "tshirt_1717", "Comfort color T-shirt"] },
        { "label": `Moss`, "value": `moss`, "enabled": ["tshirt_1717", "Comfort color T-shirt"] },
        { "label": `Bluejean`, "value": `bluejean`, "enabled": ["tshirt_1717"] },
        { "label": `Crunchberry`, "value": `crunchberry`, "enabled": ["tshirt_1717"] },
        { "label": `Graphite`, "value": `graphite`, "enabled": ["tshirt_1717"] },
        { "label": `Grey`, "value": `grey`, "enabled": ["tshirt_1717"] },
        { "label": `Yam`, "value": `yam`, "enabled": ["tshirt_1717"] },
        { "label": `As Design`, "value": `asdesign`, "enabled": ["premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
        { "label": `Same Design`, "value": `samedesign`, "enabled": ["premium3dtshirt", "sweatshirtunisex", "hoodie", "tanktopmen", "tanktopwomen", "premiumpoloshirtmen", "premiumpoloshirtwomen"] },
        { "label": `Antiqueirishgreen`, "value": `antiqueirishgreen`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Antiqueorange`, "value": `antiqueorange`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Antiquesapphire`, "value": `antiquesapphire`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Azalea`, "value": `azalea`, "enabled": ["tshirt_5000_us1", "sweatshirt_18000_us1"] },
        { "label": `Cardinalred`, "value": `cardinalred`, "enabled": ["tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1"] },
        { "label": `Carolinablue`, "value": `carolinablue`, "enabled": ["tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "Sweatshirt"] },
        { "label": `Cobalt`, "value": `cobalt`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Coralsilk`, "value": `coralsilk`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Cornsilk`, "value": `cornsilk`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Daisy`, "value": `daisy`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Darkchocolate`, "value": `darkchocolate`, "enabled": ["tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Gold`, "value": `gold`, "enabled": ["tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "tshirt_3001", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Graphiteheather`, "value": `graphiteheather`, "enabled": ["tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1"] },
        { "label": `Heliconia`, "value": `heliconia`, "enabled": ["tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "tshirt_1717", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Icegrey`, "value": `icegrey`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Irishgreen`, "value": `irishgreen`, "enabled": ["tshirt_5000_us1", "sweatshirt_18000_us1", "hoodie_18500_us1", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Kiwi`, "value": `kiwi`, "enabled": ["tshirt_5000_us1", "sweatshirt_18000_us1", "tshirt_1717"] },
        { "label": `Lime`, "value": `lime`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Turfgreen`, "value": `turfgreen`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Tweed`, "value": `tweed`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Electricgreen`, "value": `electricgreen`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Heathernavy`, "value": `heathernavy`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Heatherred`, "value": `heatherred`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Tropicalblue`, "value": `tropicalblue`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Mintgreen`, "value": `mintgreen`, "enabled": ["tshirt_5000_us1"] },
        { "label": `Antiquecherryred`, "value": `antiquecherryred`, "enabled": ["sweatshirt_18000_us1", "hoodie_18500_us1"] },
        { "label": `Cherryred`, "value": `cherryred`, "enabled": ["sweatshirt_18000_us1"] },
        { "label": `Garnet`, "value": `garnet`, "enabled": ["sweatshirt_18000_us1"] },
        { "label": `Heathersportscarletred`, "value": `heathersportscarletred`, "enabled": ["sweatshirt_18000_us1"] },
        { "label": `Heathersportdarkgreen`, "value": `heathersportdarkgreen`, "enabled": ["sweatshirt_18000_us1"] },
        { "label": `Heathersportdarknavy`, "value": `heathersportdarknavy`, "enabled": ["sweatshirt_18000_us1", "hoodie_18500_us1"] },
        { "label": `Heathersportdarkmaroon`, "value": `heathersportdarkmaroon`, "enabled": ["sweatshirt_18000_us1"] },
        { "label": `Heathersportroyal`, "value": `heathersportroyal`, "enabled": ["sweatshirt_18000_us1"] },
        { "label": `Indigoblue`, "value": `indigoblue`, "enabled": ["sweatshirt_18000_us1", "hoodie_18500_us1"] },
        { "label": `Safetygreen`, "value": `safetygreen`, "enabled": ["sweatshirt_18000_us1"] },
        { "label": `Sapphire`, "value": `sapphire`, "enabled": ["sweatshirt_18000_us1"] },
        { "label": `Safetyorange`, "value": `safetyorange`, "enabled": ["hoodie_18500_us1"] },
        { "label": `Aqua`, "value": `aqua`, "enabled": ["tshirt_3001"] },
        { "label": `Army`, "value": `army`, "enabled": ["tshirt_3001"] },
        { "label": `Athleticheather`, "value": `athleticheather`, "enabled": ["tshirt_3001"] },
        { "label": `Babyblue`, "value": `babyblue`, "enabled": ["tshirt_3001"] },
        { "label": `Berry`, "value": `berry`, "enabled": ["tshirt_3001", "tshirt_1717"] },
        { "label": `Blackheather`, "value": `blackheather`, "enabled": ["tshirt_3001"] },
        { "label": `Canvasred`, "value": `canvasred`, "enabled": ["tshirt_3001"] },
        { "label": `Cardinal`, "value": `cardinal`, "enabled": ["tshirt_3001"] },
        { "label": `Charitypink`, "value": `charitypink`, "enabled": ["tshirt_3001"] },
        { "label": `Darkolive`, "value": `darkolive`, "enabled": ["tshirt_3001"] },
        { "label": `Deepheather`, "value": `deepheather`, "enabled": ["tshirt_3001"] },
        { "label": `Forest`, "value": `forest`, "enabled": ["tshirt_g5000", "sweatshirt_g18000", "hoodie_g18500", "tanktop_g2200", "tshirt_b3001", "tshirt_c1717", "totebag", "Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Heatherclay`, "value": `heatherclay`, "enabled": ["tshirt_3001"] },
        { "label": `Heathercolumbiablue`, "value": `heathercolumbiablue`, "enabled": ["tshirt_3001"] },
        { "label": `Heathergreen`, "value": `heathergreen`, "enabled": ["tshirt_3001"] },
        { "label": `Heathericeblue`, "value": `heathericeblue`, "enabled": ["tshirt_3001"] },
        { "label": `Heatherkelly`, "value": `heatherkelly`, "enabled": ["tshirt_3001"] },
        { "label": `Heathermauve`, "value": `heathermauve`, "enabled": ["tshirt_3001"] },
        { "label": `Heathermint`, "value": `heathermint`, "enabled": ["tshirt_3001"] },
        { "label": `Heathernavy`, "value": `heathernavy`, "enabled": ["tshirt_3001"] },
        { "label": `Heatherolive`, "value": `heatherolive`, "enabled": ["tshirt_3001"] },
        { "label": `Heatherpeach`, "value": `heatherpeach`, "enabled": ["tshirt_3001"] },
        { "label": `Heatherraspberry`, "value": `heatherraspberry`, "enabled": ["tshirt_3001"] },
        { "label": `Heatherred`, "value": `heatherred`, "enabled": ["tshirt_3001"] },
        { "label": `Heatherslate`, "value": `heatherslate`, "enabled": ["tshirt_3001"] },
        { "label": `Heatherteampurple`, "value": `heatherteampurple`, "enabled": ["tshirt_3001"] },
        { "label": `Heathertrueroyal`, "value": `heathertrueroyal`, "enabled": ["tshirt_3001"] },
        { "label": `Kelly`, "value": `kelly`, "enabled": ["tshirt_3001"] },
        { "label": `Maizeyellow`, "value": `maizeyellow`, "enabled": ["tshirt_3001"] },
        { "label": `Mint`, "value": `mint`, "enabled": ["tshirt_3001"] },
        { "label": `Olive`, "value": `olive`, "enabled": ["tshirt_3001"] },
        { "label": `Pink`, "value": `pink`, "enabled": ["tshirt_3001"] },
        { "label": `Silver`, "value": `silver`, "enabled": ["tshirt_3001"] },
        { "label": `Softpink`, "value": `softpink`, "enabled": ["tshirt_3001"] },
        { "label": `Solidblackblend`, "value": `solidblackblend`, "enabled": ["tshirt_3001"] },
        { "label": `Teal`, "value": `teal`, "enabled": ["tshirt_3001"] },
        { "label": `Teampurple`, "value": `teampurple`, "enabled": ["tshirt_3001"] },
        { "label": `Trueroyal`, "value": `trueroyal`, "enabled": ["tshirt_3001"] },
        { "label": `Vintageblack`, "value": `vintageblack`, "enabled": ["tshirt_3001"] },
        { "label": `Yellow`, "value": `yellow`, "enabled": ["tshirt_3001"] },
        { "label": `Asphalt`, "value": `asphalt`, "enabled": ["tshirt_3001"] },
        { "label": `Darkgrey`, "value": `darkgrey`, "enabled": ["tshirt_3001"] },
        { "label": `Heatherprismnatural`, "value": `heatherprismnatural`, "enabled": ["tshirt_3001"] },
        { "label": `Steelblue`, "value": `steelblue`, "enabled": ["tshirt_3001"] },
        { "label": `Heatherseagreen`, "value": `heatherseagreen`, "enabled": ["tshirt_3001"] },
        { "label": `Leaf`, "value": `leaf`, "enabled": ["tshirt_3001"] },
        { "label": `Softcream`, "value": `softcream`, "enabled": ["tshirt_3001"] },
        { "label": `Brown`, "value": `brown`, "enabled": ["tshirt_3001"] },
        { "label": `Burntorange`, "value": `burntorange`, "enabled": ["tshirt_3001", "tshirt_1717", "Comfort color T-shirt"] },
        { "label": `Coral`, "value": `coral`, "enabled": ["tshirt_3001"] },
        { "label": `Deepteal`, "value": `deepteal`, "enabled": ["tshirt_3001"] },
        { "label": `Heatheraqua`, "value": `heatheraqua`, "enabled": ["tshirt_3001"] },
        { "label": `Heathermaroon`, "value": `heathermaroon`, "enabled": ["tshirt_3001"] },
        { "label": `Lilac`, "value": `lilac`, "enabled": ["tshirt_3001"] },
        { "label": `Pebble`, "value": `pebble`, "enabled": ["tshirt_3001"] },
        { "label": `Sunset`, "value": `sunset`, "enabled": ["tshirt_3001"] },
        { "label": `Turquoise`, "value": `turquoise`, "enabled": ["tshirt_3001"] },
        { "label": `Vintagewhite`, "value": `vintagewhite`, "enabled": ["tshirt_3001"] },
        { "label": `Heatherprismlilac`, "value": `heatherprismlilac`, "enabled": ["tshirt_3001"] },
        { "label": `Oxbloodblack`, "value": `oxbloodblack`, "enabled": ["tshirt_3001"] },
        { "label": `Banana`, "value": `banana`, "enabled": ["tshirt_1717"] },
        { "label": `Bay`, "value": `bay`, "enabled": ["tshirt_1717"] },
        { "label": `Blossom`, "value": `blossom`, "enabled": ["tshirt_1717"] },
        { "label": `Bluespruce`, "value": `bluespruce`, "enabled": ["tshirt_1717"] },
        { "label": `Brick`, "value": `brick`, "enabled": ["tshirt_1717"] },
        { "label": `Brightsalmon`, "value": `brightsalmon`, "enabled": ["tshirt_1717"] },
        { "label": `Butter`, "value": `butter`, "enabled": ["tshirt_1717"] },
        { "label": `Chalkymint`, "value": `chalkymint`, "enabled": ["tshirt_1717"] },
        { "label": `Chambray`, "value": `chambray`, "enabled": ["tshirt_1717"] },
        { "label": `Chili`, "value": `chili`, "enabled": ["tshirt_1717"] },
        { "label": `Citrus`, "value": `citrus`, "enabled": ["tshirt_1717"] },
        { "label": `Crimson`, "value": `crimson`, "enabled": ["tshirt_1717"] },
        { "label": `Cumin`, "value": `cumin`, "enabled": ["tshirt_1717"] },
        { "label": `Denim`, "value": `denim`, "enabled": ["tshirt_1717"] },
        { "label": `Floblue`, "value": `floblue`, "enabled": ["tshirt_1717"] },
        { "label": `Granite`, "value": `granite`, "enabled": ["tshirt_1717"] },
        { "label": `Grape`, "value": `grape`, "enabled": ["tshirt_1717"] },
        { "label": `Grass`, "value": `grass`, "enabled": ["tshirt_1717"] },
        { "label": `Hemp`, "value": `hemp`, "enabled": ["tshirt_1717"] },
        { "label": `Iceblue`, "value": `iceblue`, "enabled": ["tshirt_1717"] },
        { "label": `Islandreef`, "value": `islandreef`, "enabled": ["tshirt_1717"] },
        { "label": `Khaki`, "value": `khaki`, "enabled": ["tshirt_1717"] },
        { "label": `Lagoonblue`, "value": `lagoonblue`, "enabled": ["tshirt_1717"] },
        { "label": `Lightgreen`, "value": `lightgreen`, "enabled": ["tshirt_1717"] },
        { "label": `Melon`, "value": `melon`, "enabled": ["tshirt_1717"] },
        { "label": `Midnight`, "value": `midnight`, "enabled": ["tshirt_1717"] },
        { "label": `Mustard`, "value": `mustard`, "enabled": ["tshirt_1717"] },
        { "label": `Mysticblue`, "value": `mysticblue`, "enabled": ["tshirt_1717"] },
        { "label": `Orchid`, "value": `orchid`, "enabled": ["tshirt_1717"] },
        { "label": `Peony`, "value": `peony`, "enabled": ["tshirt_1717"] },
        { "label": `Periwinkle`, "value": `periwinkle`, "enabled": ["tshirt_1717"] },
        { "label": `Royalcaribe`, "value": `royalcaribe`, "enabled": ["tshirt_1717"] },
        { "label": `Sage`, "value": `sage`, "enabled": ["tshirt_1717"] },
        { "label": `Sandstone`, "value": `sandstone`, "enabled": ["tshirt_1717"] },
        { "label": `Seafoam`, "value": `seafoam`, "enabled": ["tshirt_1717", "Comfort color T-shirt"] },
        { "label": `Terracotta`, "value": `terracotta`, "enabled": ["tshirt_1717"] },
        { "label": `Topazblue`, "value": `topazblue`, "enabled": ["tshirt_1717"] },
        { "label": `Truenavy`, "value": `truenavy`, "enabled": ["tshirt_1717"] },
        { "label": `Vineyard`, "value": `vineyard`, "enabled": ["tshirt_1717"] },
        { "label": `Violet`, "value": `violet`, "enabled": ["tshirt_1717", "Classic T-Shirt", "Comfort color T-shirt"] },
        { "label": `Washeddenim`, "value": `washeddenim`, "enabled": ["tshirt_1717"] },
        { "label": `Watermelon`, "value": `watermelon`, "enabled": ["tshirt_1717"] },
        { "label": `Brownsavana`, "value": `brownsavana`, "enabled": ["Classic T-Shirt", "Sweatshirt", "Hoodie"] },
        { "label": `Espresso`, "value": `espresso`, "enabled": ["Comfort color T-shirt"] },
    ];
    const list_shipping_method = [
        { "label": `FirstClass`, "value": `1`, "enabled": ["flashship"] },
        { "label": `Priority`, "value": `2`, "enabled": ["flashship"] },
        { "label": `RushProduction`, "value": `3`, "enabled": ["flashship"] },
        { "label": `Expedite`, "value": `6`, "enabled": ["flashship"] },

        { "label": `Standard`, "value": `standard`, "enabled": ["mangoteeprints", "monkeykingprint"] },
        { "label": `Priority`, "value": `priority`, "enabled": ["mangoteeprints", "monkeykingprint"] },
        { "label": `Express`, "value": `express`, "enabled": ["mangoteeprints", "monkeykingprint"] },
        { "label": `Rush`, "value": `rush`, "enabled": ["mangoteeprints"] },
        { "label": `Expedite`, "value": `expedite`, "enabled": ["mangoteeprints", "monkeykingprint"] },

        { "label": `Standard`, "value": `0`, "enabled": ["gearment"] },
        { "label": `Fastship 2days`, "value": `1`, "enabled": ["gearment"] },
        { "label": `Ground`, "value": `2`, "enabled": ["gearment"] },
        { "label": `Stamp`, "value": `3`, "enabled": ["gearment"] },

        { "label": `Standard`, "value": `1`, "enabled": ["hmfulfill",] },
        { "label": `Priority`, "value": `3`, "enabled": ["hmfulfill"] },

        // { "label": `Standard`, "value": `0`, "enabled": ["lenful"] },
        // { "label": `Ground`, "value": `1`, "enabled": ["lenful"] },
        // { "label": `Express`, "value": `2`, "enabled": ["lenful"] },
        // { "label": `Special`, "value": `4`, "enabled": ["lenful"] },
        // { "label": `Us Island`, "value": `5`, "enabled": ["lenful"] },
        // { "label": `WW Standard`, "value": `6`, "enabled": ["lenful"] },
        { "label": `Shipping By Platform`, "value": `7`, "enabled": ["lenful"] },
        // { "label": `Shipping By Seller`, "value": `8`, "enabled": ["lenful"] },

        { "label": `Shipping By Tiktok`, "value": `sbtt`, "enabled": ["onospod"] },
        // { "label": `Onos Express`, "value": `onosexpress`, "enabled": ["onospod"] },
        // { "label": `COD`, "value": `cod`, "enabled": ["onospod"] },

        { "label": `Standard Ship`, "value": `standard_ship`, "enabled": ["a2kecom"] },
        { "label": `Ground Ship`, "value": `ground_ship`, "enabled": ["a2kecom"] },
        { "label": `Fast Ship`, "value": `fast_ship`, "enabled": ["a2kecom"] },

        { "label": `Standard`, "value": `standard`, "enabled": ["printeeshub"] },
        { "label": `Rush Product`, "value": `rush_product`, "enabled": ["printeeshub"] },
        { "label": `Expedite Tiktok`, "value": `expedite_tiktok`, "enabled": ["printeeshub"] },
        
        { "label": `Vip`, "value": `vip`, "enabled": ["monkeykingprint"] },
    ];
    const list_printing_area = [
        { "label": `One side`, "value": `oneside`, "enabled": ["lenful"] },
        { "label": `Both sides`, "value": `bothsides`, "enabled": ["lenful"] },
    ];

    useEffect(() => {
        getDesigns({ page: 1, sizeperpage: 9999 });
    }, [getDesigns]);

    useEffect(() => {
        (async () => {
            var res = await getProductTikTokMainImageByApiProductId(product_id);
            if (res?.producttiktok?.main_images?.length > 0) {
                setMainImages(res?.producttiktok?.main_images || []);
            }
        })();
    }, [getProductTikTokMainImageByApiProductId, product_id]);

    useEffect(() => {
        setMockup({
            ...mockup,
            [line_item_id]: {
                ...mockup?.[line_item_id],
                "Front": mockup_front || "",
                "Back": mockup_back || "",
                "Neck": mockup_neck || "",
                "Right": mockup_right || "",
                "Left": mockup_left || "",
                "AOP_3D": mockup_aop_3d || "",
            }
        });
    }, [mockup_front, mockup_back, mockup_neck, mockup_right, mockup_left, mockup_aop_3d]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDesign({
            ...design,
            [line_item_id]: {
                ...design?.[line_item_id],
                "Front": design_front || "",
                "Back": design_back || "",
                "Neck": design_neck || "",
                "Right": design_right || "",
                "Left": design_left || "",
                "AOP_3D": design_aop_3d || "",
            }
        });
    }, [design_front, design_back, design_neck, design_right, design_left, design_aop_3d]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((printer && manufacturer && type && shipping_method && color && size) || (printer === "kvfulfill" && manufacturer && type)) {
            setObjPrinter({
                ...obj_printer,
                [line_item_id]: {
                    ...obj_printer?.[line_item_id],
                    printer,
                    manufacturer,
                    type,
                    printing_area,
                    shipping_method,
                    color,
                    size,
                    base_cost
                }
            });
        }
    }, [printer, manufacturer, type, printing_area, shipping_method, color, size, base_cost]); // eslint-disable-line react-hooks/exhaustive-deps

    const onClickSaveEditOrder = () => {
        saveEditOrder({ _id, design_fee, mockup, design, printer: obj_printer, shipping_label, page: props.productsettings.page, sizePerPage: props.productsettings.sizePerPage, searchText: props.productsettings.searchText, filter_printer: props.productsettings.filter_printer, filter_shop_code: props.productsettings.filter_shop_code, filter_status: props.productsettings.filter_status, filter_work_status: props.productsettings.filter_work_status, filter_staff: props.productsettings.filter_staff }, openModal);
    };

    const onChange = (e) => {
        if (e.target.name === "mockup_front") {
            setMockupFront(e.target.value);
        }
        if (e.target.name === "mockup_back") {
            setMockupBack(e.target.value);
        }
        if (e.target.name === "mockup_neck") {
            setMockupNeck(e.target.value);
        }
        if (e.target.name === "mockup_right") {
            setMockupRight(e.target.value);
        }
        if (e.target.name === "mockup_left") {
            setMockupLeft(e.target.value);
        }
        if (e.target.name === "mockup_aop_3d") {
            setMockupAop3d(e.target.value);
        }
        if (e.target.name === "design_fee") {
            setDesignFee({
                ...design_fee,
                [line_item_id]: e.target.value
            });
        }
        if (e.target.name === "design_front") {
            setDesignFront(e.target.value);
        }
        if (e.target.name === "design_back") {
            setDesignBack(e.target.value);
        }
        if (e.target.name === "design_neck") {
            setDesignNeck(e.target.value);
        }
        if (e.target.name === "design_right") {
            setDesignRight(e.target.value);
        }
        if (e.target.name === "design_left") {
            setDesignLeft(e.target.value);
        }
        if (e.target.name === "design_aop_3d") {
            setDesignAop3d(e.target.value);
        }
        if (e.target.name === "shipping_label") {
            setShippingLabel(e.target.value);
        }
    }

    const onClickMockup = (type, mockup_type) => {
        if (order_status === "worked") {
            return false;
        }
        if (type === "confirm") {
            if (mockup_type === "Front") {
                setMockupFront(mockup_front);
            }
            if (mockup_type === "Back") {
                setMockupBack(mockup_back);
            }
            if (mockup_type === "Neck") {
                setMockupNeck(mockup_neck);
            }
            if (mockup_type === "Right") {
                setMockupRight(mockup_right);
            }
            if (mockup_type === "Left") {
                setMockupLeft(mockup_left);
            }
            if (mockup_type === "AOP_3D") {
                setMockupAop3d(mockup_aop_3d);
            }
        }
        if (mockup_type === "Front") {
            setIsEditMockupFront(!isEditMockupFront);
        }
        if (mockup_type === "Back") {
            setIsEditMockupBack(!isEditMockupBack);
        }
        if (mockup_type === "Neck") {
            setIsEditMockupNeck(!isEditMockupNeck);
        }
        if (mockup_type === "Right") {
            setIsEditMockupRight(!isEditMockupRight);
        }
        if (mockup_type === "Left") {
            setIsEditMockupLeft(!isEditMockupLeft);
        }
        if (mockup_type === "AOP_3D") {
            setIsEditMockupAop3d(!isEditMockupAop3d);
        }
    }

    const onClickDesign = (type, design_type) => {
        if (order_status === "worked") {
            return false;
        }
        if (type === "confirm") {
            if (design_type === "Front") {
                setDesignFront(design_front);
            }
            if (design_type === "Back") {
                setDesignBack(design_back);
            }
            if (design_type === "Neck") {
                setDesignNeck(design_neck);
            }
            if (design_type === "Right") {
                setDesignRight(design_right);
            }
            if (design_type === "Left") {
                setDesignLeft(design_left);
            }
            if (design_type === "AOP_3D") {
                setDesignAop3d(design_aop_3d);
            }
        }
        if (design_type === "Front") {
            setIsEditDesignFront(!isEditDesignFront);
        }
        if (design_type === "Back") {
            setIsEditDesignBack(!isEditDesignBack);
        }
        if (design_type === "Neck") {
            setIsEditDesignNeck(!isEditDesignNeck);
        }
        if (design_type === "Right") {
            setIsEditDesignRight(!isEditDesignRight);
        }
        if (design_type === "Left") {
            setIsEditDesignLeft(!isEditDesignLeft);
        }
        if (design_type === "AOP_3D") {
            setIsEditDesignAop3d(!isEditDesignAop3d);
        }
    }

    const onChangeDesignMatch = (e) => {
        setMockupFront(e?.mockup?.Front || "");
        setMockupBack(e?.mockup?.Back || "");
        setMockupNeck(e?.mockup?.Neck || "");
        setMockupRight(e?.mockup?.Right || "");
        setMockupLeft(e?.mockup?.Left || "");
        setMockupAop3d(e?.mockup?.AOP_3D || "");

        setDesignFront(e?.design?.Front || "");
        setDesignBack(e?.design?.Back || "");
        setDesignNeck(e?.design?.Neck || "");
        setDesignRight(e?.design?.Right || "");
        setDesignLeft(e?.design?.Left || "");
        setDesignAop3d(e?.design?.AOP_3D || "");

        setDesignMatch(e?.value || "");
    }

    const onOpenModal = () => {
        openModal();
    };

    const onKeyPress = (e) => {
        if ((/^[0-9.]+$/).test(e)) {
            e.preventDefault();
        }
    }

    const onChangePrinter = (e, name = "") => {
        if (name === "printer") {
            setPrinter(e?.value || "");
            setManufacturer("");
            setType("");
            setPrintingArea(0);
            setShippingMethod("");
            setColor("");
            setSize("");
            setBaseCost(0);
        }
        if (name === "manufacturer") {
            setManufacturer(e?.value || "");
            setType("");
            setPrintingArea(0);
            setShippingMethod("");
            setColor("");
            setSize("");
            setBaseCost(0);
        }
        if (name === "type") {
            setType(e?.value || "");
            setPrintingArea(0);
            setShippingMethod("");
            setColor("");
            setSize("");
            setBaseCost(0);
        }
        if (name === "printing_area") {
            setPrintingArea(e?.value || "");
        }
        if (name === "shipping_method") {
            setShippingMethod(e?.value || "");
        }
        if (name === "color") {
            setColor(e?.value || "");
        }
        if (name === "size") {
            setSize(e?.value || "");
        }
        if (name === "base_cost") {
            setBaseCost(e.target.value || "");
        }
    }

    return (
        <Fragment>
            <Modal centered={true} size="xl" toggle={() => { onOpenModal() }} isOpen={isOpenModalProductSettings} className="al-d-none-option-react-select">
                <Loading isLoading={isLoading} />
                <div className="modal-header">
                    <h1 className="mb-0 text-dark">{t("Printer Setting")}</h1>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <i className="ki-outline ki-cross fs-1" onClick={() => { onOpenModal() }} ></i>
                    </div>
                </div>
                <ModalBody>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{t("Main Images")}</span>
                        </label>
                        <div className="d-flex justify-content-sm-evenly mb-5">
                            {
                                main_images.map((v) => {
                                    return (
                                        <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                            <div className="symbol symbol-50px me-2">
                                                <Link to={v} target="_blank">
                                                    <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: v }, largeImage: { src: v, width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Title")}</span>
                            </label>
                            <input type="text" className="form-control" value={product_name} placeholder="" name="product_name" disabled />
                        </div>
                        <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="">{t("Shipping Label")}</span>
                            </label>
                            <input type="text" className="form-control" value={shipping_label} placeholder="" name="shipping_label" onChange={(e) => { onChange(e) }} disabled={order_status === "worked"} />
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">{t("Design match")}</span>
                            </label>
                            <Select
                                isClearable
                                isDisabled={order_status === "worked"}
                                name="design_match"
                                options={list_design}
                                className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-0 al-filter-table"
                                classNamePrefix="select"
                                value={list_design.filter(({ value }) => design_match === value)}
                                onChange={(e) => { onChangeDesignMatch(e) }}
                                placeholder={t("Select a design ... ")}
                                formatOptionLabel={(v) => (
                                    <div className="option cursor-pointer">
                                        <span className="">{v.label}</span>
                                        <div className="row">
                                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container col-md-6">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                    <span className="">{t("Mockup")}</span>
                                                </label>
                                                <div className="d-flex justify-content-sm-evenly mb-5">
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">Front</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.mockup?.Front ?
                                                                    <Link to={v.mockup?.Front} target="_blank" >
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.Front) }, largeImage: { src: formatterImage(v?.mockup?.Front), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">Back</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.mockup?.Back ?
                                                                    <Link to={v.mockup?.Back} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.Back) }, largeImage: { src: formatterImage(v?.mockup?.Back), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">Neck</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.mockup?.Neck ?
                                                                    <Link to={v.mockup?.Neck} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.Neck) }, largeImage: { src: formatterImage(v?.mockup?.Neck), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-sm-evenly mb-5">
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">Right</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.mockup?.Right ?
                                                                    <Link to={v.mockup?.Right} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.Right) }, largeImage: { src: formatterImage(v?.mockup?.Right), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">Left</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.mockup?.Left ?
                                                                    <Link to={v.mockup?.Left} target="_blank" >
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.Left) }, largeImage: { src: formatterImage(v?.mockup?.Left), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">AOP_3D</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.mockup?.AOP_3D ?
                                                                    <Link to={v.mockup?.AOP_3D} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.mockup?.AOP_3D) }, largeImage: { src: formatterImage(v?.mockup?.AOP_3D), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container col-md-6">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                    <span className="">{t("Design")}</span>
                                                </label>
                                                <div className="d-flex justify-content-sm-evenly mb-5">
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">Front</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.design?.Front ?
                                                                    <Link to={v.design?.Front} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.Front) }, largeImage: { src: formatterImage(v?.design?.Front), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">Back</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.design?.Back ?
                                                                    <Link to={v.design?.Back} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.Back) }, largeImage: { src: formatterImage(v?.design?.Back), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">Neck</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.design?.Neck ?
                                                                    <Link to={v.design?.Neck} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.Neck) }, largeImage: { src: formatterImage(v?.design?.Neck), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-sm-evenly mb-5">
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">Right</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.design?.Right ?
                                                                    <Link to={v.design?.Right} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.Right) }, largeImage: { src: formatterImage(v?.design?.Right), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">Left</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.design?.Left ?
                                                                    <Link to={v.design?.Left} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.Left) }, largeImage: { src: formatterImage(v?.design?.Left), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />

                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                                        <span className="h3">AOP_3D</span>
                                                        <div className="symbol symbol-50px me-2">
                                                            {
                                                                v.design?.AOP_3D ?
                                                                    <Link to={v?.design?.AOP_3D} target="_blank">
                                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(v?.design?.AOP_3D) }, largeImage: { src: formatterImage(v?.design?.AOP_3D), width: 300, height: 300 }, enlargedImageContainerDimensions: { width: '300%', height: '300%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                                    </Link>
                                                                    :
                                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="col-6 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="">{t("Design fee")}</span>
                            </label>
                            <div className="input-group">
                                <input type="number" className="form-control" value={design_fee?.[line_item_id]} placeholder="" name="design_fee" onChange={(e) => { onChange(e) }} onKeyPress={(e) => { onKeyPress(e) }} disabled={order_status === "worked"} />
                                <span className="input-group-text">$</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container col-md-6">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="">{t("Mockup")}</span>
                            </label>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                    <span className="h3">Front</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupFront ?
                                                mockup?.[line_item_id]?.Front ?
                                                    <div onClick={() => { onClickMockup("change", "Front") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.Front) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.Front), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Front") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_front} placeholder="" name="mockup_front" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Front") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column ml-5">
                                    <span className="h3">Back</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupBack ?
                                                mockup?.[line_item_id]?.Back ?
                                                    <div onClick={() => { onClickMockup("change", "Back") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.Back) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.Back), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Back") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_back} placeholder="" name="mockup_back" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Back") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Neck</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupNeck ?
                                                mockup?.[line_item_id]?.Neck ?
                                                    <div onClick={() => { onClickMockup("change", "Neck") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.Neck) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.Neck), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Neck") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_neck} placeholder="" name="mockup_neck" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Neck") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Right</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupRight ?
                                                mockup?.[line_item_id]?.Right ?
                                                    <div onClick={() => { onClickMockup("change", "Right") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.Right) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.Right), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Right") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_right} placeholder="" name="mockup_right" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Right") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Left</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupLeft ?
                                                mockup?.[line_item_id]?.Left ?
                                                    <div onClick={() => { onClickMockup("change", "Left") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.Left) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.Left), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "Left") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_left} placeholder="" name="mockup_left" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "Left") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">AOP 3D</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditMockupAop3d ?
                                                mockup?.[line_item_id]?.AOP_3D ?
                                                    <div onClick={() => { onClickMockup("change", "AOP_3D") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(mockup?.[line_item_id]?.AOP_3D) }, largeImage: { src: formatterImage(mockup?.[line_item_id]?.AOP_3D), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickMockup("change", "AOP_3D") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={mockup_aop_3d} placeholder="" name="mockup_aop_3d" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickMockup("confirm", "AOP_3D") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container col-md-6">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="">{t("Design")}</span>
                            </label>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex align-items-center justify-content-sm-evenly flex-column">
                                    <span className="h3">Front</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignFront ?
                                                design?.[line_item_id]?.Front ?
                                                    <div onClick={() => { onClickDesign("change", "Front") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.Front) }, largeImage: { src: formatterImage(design?.[line_item_id]?.Front), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Front") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_front} placeholder="" name="design_front" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Front") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column ml-5">
                                    <span className="h3">Back</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignBack ?
                                                design?.[line_item_id]?.Back ?
                                                    <div onClick={() => { onClickDesign("change", "Back") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.Back) }, largeImage: { src: formatterImage(design?.[line_item_id]?.Back), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Back") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_back} placeholder="" name="design_back" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Back") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Neck</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignNeck ?
                                                design?.[line_item_id]?.Neck ?
                                                    <div onClick={() => { onClickDesign("change", "Neck") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.Neck) }, largeImage: { src: formatterImage(design?.[line_item_id]?.Neck), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Neck") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_neck} placeholder="" name="design_neck" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Neck") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-sm-evenly mb-5">
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Right</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignRight ?
                                                design?.[line_item_id]?.Right ?
                                                    <div onClick={() => { onClickDesign("change", "Right") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.Right) }, largeImage: { src: formatterImage(design?.[line_item_id]?.Right), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Right") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_right} placeholder="" name="design_right" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Right") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">Left</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignLeft ?
                                                design?.[line_item_id]?.Left ?
                                                    <div onClick={() => { onClickDesign("change", "Left") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.Left) }, largeImage: { src: formatterImage(design?.[line_item_id]?.Left), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "Left") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_left} placeholder="" name="design_left" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "Left") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex  align-items-center justify-content-sm-evenly flex-column mr-5">
                                    <span className="h3">AOP 3D</span>
                                    <div className="symbol symbol-50px me-2">
                                        {
                                            !isEditDesignAop3d ?
                                                design?.[line_item_id]?.AOP_3D ?
                                                    <div onClick={() => { onClickDesign("change", "AOP_3D") }}>
                                                        <ReactImageMagnify {...{ smallImage: { alt: "", width: 50, height: 50, src: formatterImage(design?.[line_item_id]?.AOP_3D) }, largeImage: { src: formatterImage(design?.[line_item_id]?.AOP_3D), width: 600, height: 600 }, enlargedImageContainerDimensions: { width: '600%', height: '600%' }, enlargedImageContainerStyle: { "zIndex": 99 }, className: "cursor-pointer", enlargedImageContainerClassName: "al-zoom-img-cus" }} />
                                                    </div>
                                                    :
                                                    <img src={require("components/assets/media/misc/image.png")} alt="" className="cursor-pointer" onClick={() => { onClickDesign("change", "AOP_3D") }} />
                                                :
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={design_aop_3d} placeholder="" name="design_aop_3d" onChange={(e) => { onChange(e) }} />
                                                    <span className="input-group-text" onClick={() => { onClickDesign("confirm", "AOP_3D") }}><i className="ki-outline ki-check fs-2 cursor-pointer"></i></span>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="me-2">{t("Printer")}</span>
                            <span className="text-primary fw-semibold d-block">{sku_name}</span>
                        </label>
                    </div>
                    <div className="row">
                        <div className={cx("d-flex flex-column mb-1 fv-row fv-plugins-icon-container", { "col-3": printer === "lenful", "col-4": printer !== "lenful" })}>
                            <Select
                                isDisabled={order_status === "worked"}
                                isClearable
                                name="printer"
                                options={list_printer}
                                className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                classNamePrefix="select"
                                value={list_printer.filter(({ value }) => printer === value)}
                                onChange={(e) => { onChangePrinter(e, "printer") }}
                                placeholder={t("Select a printer ... ")}
                            />
                        </div>
                        <div className={cx("d-flex flex-column mb-1 fv-row fv-plugins-icon-container", { "col-3": printer === "lenful", "col-4": printer !== "lenful" })}>
                            {
                                printer === "flashship" || printer === "mangoteeprints" || printer === "gearment" || printer === "other" || printer === "hmfulfill" || printer === "lenful" || printer === "onospod" || printer === "a2kecom" || printer === "printeeshub" || printer === "monkeykingprint" || printer === "kvfulfill" ?
                                    <Select
                                        isDisabled={order_status === "worked"}
                                        isClearable
                                        name="manufacturer"
                                        options={list_manufacturer}
                                        className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                        classNamePrefix="select"
                                        value={list_manufacturer.filter(({ value }) => manufacturer === value)}
                                        onChange={(e) => { onChangePrinter(e, "manufacturer") }}
                                        placeholder={t("Select a manufacturer ... ")}
                                        isOptionDisabled={(option) => !option.enabled?.includes(printer)}
                                    />
                                : ""
                            }
                        </div>
                        <div className={cx("d-flex flex-column mb-1 fv-row fv-plugins-icon-container", { "col-3": printer === "lenful", "col-4": printer !== "lenful" })}>                 
                            {
                                printer === "flashship" || printer === "mangoteeprints" || printer === "gearment" || printer === "other" || printer === "hmfulfill" || printer === "lenful" || printer === "onospod" || printer === "a2kecom" || printer === "printeeshub" || printer === "monkeykingprint" || printer === "kvfulfill" ?
                                    <Select
                                        isDisabled={order_status === "worked"}
                                        isClearable
                                        isCreate
                                        name="type"
                                        options={list_type}
                                        className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                        classNamePrefix="select"
                                        value={list_type.filter(({ value }) => type === value)}
                                        onChange={(e) => { onChangePrinter(e, "type") }}
                                        placeholder={t("Select a type ... ")}
                                        isOptionDisabled={(option) => !option.enabled?.includes(manufacturer)}
                                    />
                                : ""
                            }
                        </div>
                        <div className={cx("d-flex flex-column mb-1 fv-row fv-plugins-icon-container", { "col-3": printer === "lenful", "col-4": printer !== "lenful" })}>
                            {
                                printer === "lenful" ?
                                    <Select
                                        isDisabled={order_status === "worked"}
                                        isClearable
                                        name="print_area"
                                        options={list_printing_area}
                                        className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                        classNamePrefix="select"
                                        value={list_printing_area.filter(({ value }) => printing_area === value)}
                                        onChange={(e) => { onChangePrinter(e, "printing_area") }}
                                        placeholder={t("Select a printing area ... ")}
                                        isOptionDisabled={(option) => !option.enabled?.includes(printer)}
                                    />
                                : ""
                            }
                        </div>
                    </div>
                    {
                        type && printer !== "kvfulfill" ?
                            <div className="row">
                                <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span className="required">{t("Shipping method")}</span>
                                    </label>
                                    {
                                        printer === "flashship" || printer === "mangoteeprints" || printer === "gearment" || printer === "hmfulfill" || printer === "lenful" || printer === "onospod" || printer === "a2kecom" || printer === "printeeshub" || printer === "monkeykingprint" ?
                                            <Select
                                                isDisabled={order_status === "worked"}
                                                isClearable
                                                isCreate
                                                name="shipping_method"
                                                options={list_shipping_method}
                                                className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                classNamePrefix="select"
                                                value={list_shipping_method.filter(({ value, enabled }) => shipping_method === value && enabled?.includes(printer))}
                                                onChange={(e) => { onChangePrinter(e, "shipping_method") }}
                                                placeholder={t("Select a shipping method ... ")}
                                                isOptionDisabled={(option) => !option.enabled?.includes(printer)}
                                            />
                                        : ""
                                    }
                                    {
                                        printer === "other" ?
                                            <CreatableSelect
                                                isDisabled={order_status === "worked"}
                                                name="shipping_method"
                                                options={list_shipping_method}
                                                className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                classNamePrefix="select"
                                                value={{ "label": shipping_method, "value": shipping_method }}
                                                onChange={(e) => { onChangePrinter(e, "shipping_method") }}
                                                placeholder={t("Select a shipping method ... ")}
                                            />
                                        : ""
                                    }
                                </div>
                                <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span className="required">{t("Color")}</span>
                                    </label>
                                    {
                                        printer === "flashship" || printer === "mangoteeprints" || printer === "gearment" || printer === "hmfulfill" || printer === "lenful" || printer === "onospod" || printer === "a2kecom" || printer === "printeeshub" || printer === "monkeykingprint" ?
                                            <Select
                                                isDisabled={order_status === "worked"}
                                                isClearable
                                                name="color"
                                                options={list_color}
                                                className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                classNamePrefix="select"
                                                value={list_color.filter(({ value }) => color === value)}
                                                onChange={(e) => { onChangePrinter(e, "color") }}
                                                placeholder={t("Select a color ... ")}
                                                isOptionDisabled={(option) => !option.enabled?.includes(type)}
                                            />
                                        : ""
                                    }
                                    {
                                        printer === "other" ?
                                            <CreatableSelect
                                                isDisabled={order_status === "worked"}
                                                name="color"
                                                options={list_color}
                                                className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                classNamePrefix="select"
                                                value={{ "label": color, "value": color }}
                                                onChange={(e) => { onChangePrinter(e, "color") }}
                                                placeholder={t("Select a color ... ")}
                                            />
                                        : ""
                                    }
                                </div>
                                <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span className="required">{t("Size")}</span>
                                    </label>
                                    {
                                        printer === "flashship" || printer === "mangoteeprints" || printer === "gearment" || printer === "hmfulfill" || printer === "lenful" || printer === "onospod" || printer === "a2kecom" || printer === "printeeshub" || printer === "monkeykingprint" ?
                                            <Select
                                                isDisabled={order_status === "worked"}
                                                isClearable
                                                name="size"
                                                options={list_size}
                                                className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                classNamePrefix="select"
                                                value={list_size.filter(({ value }) => size === value)}
                                                onChange={(e) => { onChangePrinter(e, "size") }}
                                                placeholder={t("Select a size ... ")}
                                                isOptionDisabled={(option) => !option.enabled?.includes(type)}
                                            />
                                            : ""
                                    }
                                    {
                                        printer === "other" ?
                                            <CreatableSelect
                                                isDisabled={order_status === "worked"}
                                                name="size"
                                                options={list_size}
                                                className="basic-multi-select al-custom-select-search-variants mt-1 me-2 mb-3 al-filter-table"
                                                classNamePrefix="select"
                                                value={{ "label": size, "value": size }}
                                                onChange={(e) => { onChangePrinter(e, "size") }}
                                                placeholder={t("Select a size ... ")}
                                            />
                                            : ""
                                    }
                                </div>
                                <div className="col-3 d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span className="">{t("Basecost")}</span>
                                    </label>
                                    <div className="input-group">
                                        <input type="number" className="form-control al-input-basecost-order-cus" value={base_cost} placeholder="" name="base_cost" onChange={(e) => { onChangePrinter(e, "base_cost") }} onKeyPress={(e) => { onKeyPress(e) }} disabled={order_status === "worked"} />
                                        <span className="input-group-text al-input-group-basecost-order-cus">$</span>
                                    </div>
                                </div>
                            </div>
                        : ""
                    }
                </ModalBody>
                <ModalFooter>
                    <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3" onClick={() => { onOpenModal() }} >{t("Cancel")}</button>
                    {
                        order_status !== "worked" ?
                            <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary" onClick={() => { onClickSaveEditOrder() }}  >{t("Save")}</button>
                        : ""
                    }
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

Modalproductsettings.propTypes = {
    getDesigns: PropTypes.func.isRequired,
    saveEditOrder: PropTypes.func.isRequired,
    getProductTikTokMainImageByApiProductId: PropTypes.func.isRequired,
    formatterImage: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    order: state.order,
    design: state.design
});
const mapDispatchToProps = {
    getDesigns,
    saveEditOrder,
    getProductTikTokMainImageByApiProductId,
    formatterImage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Modalproductsettings);